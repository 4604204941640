import { Mail } from "@material-ui/icons";
import React from "react";

export function NoteBadge() {
  return (
    <div
      style={{
        position: "absolute",
        top: 42,
        right: -4,
        color: "red",
      }}
    >
      <Mail style={{ fontSize: "20px" }} />
    </div>
  );
}
