import {
  Box,
  Card,
  CardContent,
  Popover,
  Slider,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";
import {
  isMultisiteFeatureFlagEnabled,
  LocalStorageSettings,
  minutesFromStartOfDayToHHMM,
} from "../../utils/util";

interface GlobalSettingsProps {
  anchorEl: Element | null;
  onCloseRequest: () => void;
  localStorageSettings: LocalStorageSettings;
  setLocalStorageSettings: (settings: LocalStorageSettings) => void;
  displayDisabledSensors: boolean;
  setDisplayDisabledSensors: (x: boolean) => void;
  bypassCoefficients: boolean;
  setBypassCoefficients: (x: boolean) => void;
}

export const GlobalSettings = ({
  anchorEl,
  onCloseRequest,
  localStorageSettings,
  setLocalStorageSettings,
  displayDisabledSensors,
  setDisplayDisabledSensors,
  bypassCoefficients,
  setBypassCoefficients,
}: GlobalSettingsProps) => {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    onCloseRequest();
  };

  return (
    <Fragment>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card style={{ height: "100%", padding: "10px", zIndex: 4 }}>
          <CardContent>
            <form noValidate autoComplete="off">
              {isMultisiteFeatureFlagEnabled() && ( // hack: feature flag
                <div>
                  <Typography id="range-slider" variant="overline" gutterBottom>
                    Vue multisite &nbsp;
                    <Switch
                      color="primary"
                      checked={localStorageSettings.multiSiteView}
                      onChange={(e) =>
                        setLocalStorageSettings({
                          ...localStorageSettings,
                          multiSiteView: e.currentTarget.checked,
                        })
                      }
                    />
                  </Typography>
                </div>
              )}
              <div>
                <Typography id="range-slider" variant="overline" gutterBottom>
                  Afficher les capteurs cachés &nbsp;
                  <Switch
                    color="primary"
                    checked={displayDisabledSensors}
                    onChange={(e) =>
                      setDisplayDisabledSensors(e.currentTarget.checked)
                    }
                  />
                </Typography>
              </div>

              <div>
                <Typography id="range-slider" variant="overline" gutterBottom>
                  Ignorer les coefficients &nbsp;
                </Typography>
                <Switch
                  color="primary"
                  checked={bypassCoefficients}
                  onChange={(e) =>
                    setBypassCoefficients(e.currentTarget.checked)
                  }
                />
              </div>
              <Box>
                <Typography id="range-slider" variant="overline" gutterBottom>
                  Plage d'affichage
                </Typography>
                <Slider
                  valueLabelFormat={minutesFromStartOfDayToHHMM}
                  value={localStorageSettings.displayTimeBounds}
                  min={0}
                  max={60 * 24 - 1}
                  onChange={(event: any, newValueRaw: number | number[]) => {
                    const newValue = newValueRaw as number[];
                    if (newValue[0] === newValue[1]) return;
                    setLocalStorageSettings({
                      ...localStorageSettings,
                      displayTimeBounds: [newValue[0], newValue[1]],
                    });
                  }}
                  valueLabelDisplay="off"
                  marks={[
                    {
                      value: localStorageSettings.displayTimeBounds[0],
                      label: minutesFromStartOfDayToHHMM(
                        localStorageSettings.displayTimeBounds[0]
                      ),
                    },
                    {
                      value: localStorageSettings.displayTimeBounds[1],
                      label: minutesFromStartOfDayToHHMM(
                        localStorageSettings.displayTimeBounds[1]
                      ),
                    },
                  ]}
                  aria-labelledby="range-slider"
                />
              </Box>
            </form>
          </CardContent>
        </Card>
      </Popover>
    </Fragment>
  );
};
