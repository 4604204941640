import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  MenuItem,
  Snackbar,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AppColor from "../../assets/Color";
import { Sensor, TooltipStringInfo, UserType, View } from "../../model/types";
import { setAlarm } from "../../repository/network";
import { isMobile, startOfDayToHHMMFromMinutes } from "../../utils/util";
import AcceptSmsDialog from "./AcceptSmsDialog";

interface CreateAlarmDialogParams {
  open: boolean;
  handleClose: () => void;
  view: View;
  sensor: Sensor;
  setSensor: (sensor: Sensor, persist?: boolean) => void;
  tooltipStringInfo: TooltipStringInfo;
  userData: UserType;
  updateUserData: (data: UserType) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  stepperRoot: {
    padding: 5,
  },
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  label: {
    fontSize: "14px",
  },
}));

export const CreateAlarmDialog = ({
  open,
  handleClose,
  view,
  sensor,
  setSensor,
  tooltipStringInfo,
  userData,
  updateUserData,
}: CreateAlarmDialogParams) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState<"value">("value");

  const [fromToSelected, setFromToSelected] = useState(false);
  const [fromValue, setFromValue] = useState("08:00");
  const [toValue, setToValue] = useState("18:00");

  const [conditionSelected, setConditionSelected] = useState(true);
  const [condition, setCondition] = useState("<=");
  const [conditionValue, setConditionValue] = useState("0");

  const [nbConditionSelected, setNbConditionSelected] = useState(false);
  const [nbCondition, setNbCondition] = useState("1");

  const [supportNotificationEmail, setSupportNotificationEmail] = useState(
    true
  );
  const [acceptSmsOpen, setAcceptSmsOpen] = useState(false);
  const [supportNotificationSms, setSupportNotificationSms] = useState(false);
  const isPhoneNumberValid =
    userData.phoneNumber !== null && /^\+33\d{9}/.test(userData.phoneNumber);
  const [valueDelayNotification, setValueDelayNotification] = useState("6");
  const [delayNotification, setDelayNotification] = useState("heures");
  const step1Form = useRef<HTMLFormElement>(null);
  const step2Form = useRef<HTMLFormElement>(null);
  const sensorLabel =
    sensor.label ??
    `Ond ${tooltipStringInfo.inverterSN} Str ${tooltipStringInfo.stringIndex}`;

  useEffect(() => {
    if (fromToSelected === false) {
      setFromValue("00:00");
      setToValue("23:59");
    }
  }, [fromToSelected]);

  useEffect(() => {
    if (nbConditionSelected === false) {
      setNbCondition("1");
    }
  }, [nbConditionSelected]);

  const handleNext = useCallback(() => {
    if (activeStep === 1) {
      if (!step1Form.current!.reportValidity()) {
        return;
      }
    }
    if (activeStep === 2) {
      if (!step2Form.current!.reportValidity()) {
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, step1Form.current, step2Form.current]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getContiguousMatchesBeforeTrigger = () => {
    if (delayNotification === "heures") {
      return Number(valueDelayNotification) * 60;
    } else {
      return Number(valueDelayNotification) * 1440;
    }
  };

  const Blue = (props: any) => (
    <span style={{ color: AppColor.blueDataview }} {...props} />
  );

  function showSmsPreview(content: string) {
    return (
      <>
        <Typography
          style={{
            color: AppColor.textColor,
            fontWeight: "bold",
            fontSize: "13px",
            marginBottom: "13px",
          }}
        >
          Aperçu de l'alarm via sms :
        </Typography>
        <div
          style={{
            position: "absolute",
            fontSize: "12px",
            color: "white",
            borderRadius: "10px",
            padding: "5px",
            width: "188px",
            height: "64.67px",
            zIndex: 100,
          }}
        >
          {content}
        </div>
        <svg
          width="200"
          height="77"
          viewBox="0 0 200 77"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C4.47715 0 0 4.47715 0 10V54.6667C0 60.1895 4.47715 64.6667 10 64.6667H182.767C185.617 67.9634 187.867 71.891 189.702 76.6729L190.54 64.6523C195.812 64.3716 200 60.0082 200 54.6667V10C200 4.47715 195.523 0 190 0H10Z"
            fill="#4983AE"
          />
        </svg>
      </>
    );
  }

  function notificationInfoText(email: boolean, sms: boolean) {
    return (
      <>
        {email && !sms && (
          <>
            <Blue>par email</Blue>. Si l'alarme se déclenche à nouveau, vous ne
            recevrez pas d'email avant{" "}
          </>
        )}

        {sms && !email && (
          <>
            <Blue>par sms</Blue>. Si l'alarme se déclenche à nouveau, vous ne
            recevrez pas de sms avant{" "}
          </>
        )}

        {email && sms && (
          <>
            <Blue>par email et sms</Blue>. Si l'alarme se déclenche à nouveau,
            vous ne recevrez pas d'email ni de sms avant{" "}
          </>
        )}
      </>
    );
  }

  function truncate(text: string) {
    return text.substr(0, 10);
  }

  //regex for GSM 03.38
  function removeSpecialCharacters(text: string) {
    return text.replace(
      /[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u0027\u0022\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\#$%&amp;()*+,\\./\-:;&lt;=&gt;?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*/g,
      ""
    );
  }

  function formatSmsText(text: string) {
    return truncate(removeSpecialCharacters(text));
  }

  const getWatchFrom = () => {
    if (fromToSelected) {
      return startOfDayToHHMMFromMinutes(fromValue);
    } else {
      return null;
    }
  };

  const getWatchTo = () => {
    if (fromToSelected) {
      return startOfDayToHHMMFromMinutes(toValue);
    } else {
      return null;
    }
  };

  const conditions: Record<string, string> = {
    "<": "<",
    "<=": "≤",
    "=": "=",
    ">=": "≥",
    ">": ">",
    "!=": "≠",
  };
  const handleValidate = async () => {
    setLoading(true);

    const res = await setAlarm({
      inverter_id: tooltipStringInfo.inverterId,
      sensor_index: sensor.sensor_index,
      watch_from: getWatchFrom(),
      watch_to: getWatchTo(),
      type: type,
      min_time_between_notifications_minutes: getContiguousMatchesBeforeTrigger(),
      contiguous_matches_before_trigger: Number(nbCondition),
      operation: condition,
      threshold: Number(conditionValue),
      email: supportNotificationEmail,
      sms: supportNotificationSms,
    });

    if (res.status === 200) {
      setLoading(false);
      setActiveStep(0);
      handleClose();

      setSensor(
        {
          ...sensor,
          alarmDefinitions: [
            ...(sensor.alarmDefinitions ?? []),
            { ...(await res.json()), unacknowledgedEvents: [] },
          ],
        },
        false
      );

      return;
    } else {
      setLoading(false);
      setError(true);
      return;
    }
  };

  function getSteps() {
    return [
      "Définition du type de l'alarme",
      "Conditions de déclenchement",
      "Paramètre de notification",
      "Validation",
    ];
  }
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Box display="flex" flexDirection="column">
            <img
              style={{
                height: 80,
                width: 160,
                backgroundColor: AppColor.blueDataview,
                padding: 2,
                marginBottom: 5,
              }}
              src={require("../../assets/img/alarme_type_valeur.png")}
            />
            <Box
              style={{
                color: AppColor.blueDataview,
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Alarme sur seuil
            </Box>
            <Box style={{ color: AppColor.textColor, fontSize: "14px" }}>
              Se déclenche si la valeur dépasse un seuil haut ou un seuil bas
            </Box>
          </Box>
        );
      case 1:
        return (
          <form ref={step1Form}>
            <Box
              flexWrap="wrap"
              display="flex"
              flexDirection="row"
              alignItems="center"
              marginBottom={2}
            >
              <Checkbox
                checked={conditionSelected}
                disabled
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
              />
              <Box marginRight={1} style={{ fontSize: "14px" }}>
                Valeur{" "}
              </Box>
              <ToggleButtonGroup
                size="small"
                value={condition}
                exclusive
                onChange={(event, newValue) => {
                  if (Object.keys(conditions).includes(newValue)) {
                    setCondition(newValue);
                  }
                }}
              >
                {Object.entries(conditions).map(([key, value]) => (
                  <ToggleButton key={key} value={key}>
                    {value}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <Box marginX={1} style={{ fontSize: "14px" }}>
                {" "}
                à{" "}
              </Box>
              <TextField
                type="number"
                required
                value={conditionValue}
                onChange={(event) => setConditionValue(event.target.value)}
                variant="outlined"
                size="small"
                style={{ width: 70 }}
              />
            </Box>
            <Box
              flexWrap="wrap"
              display="flex"
              flexDirection="row"
              alignItems="center"
              marginBottom={2}
            >
              <Checkbox
                checked={fromToSelected}
                onChange={(e) => {
                  setFromToSelected(e.target.checked);
                }}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
              />

              <Box marginRight={1} style={{ fontSize: "14px" }}>
                Entre{" "}
              </Box>
              <TextField
                type="time"
                required={fromToSelected}
                disabled={!fromToSelected}
                value={fromValue}
                onChange={(event) => setFromValue(event.target.value)}
                variant="outlined"
                size="small"
                style={{ marginTop: 2 }}
              />
              <Box marginX={1} style={{ fontSize: "14px" }}>
                {" "}
                et{" "}
              </Box>
              <TextField
                type="time"
                required={fromToSelected}
                disabled={!fromToSelected}
                value={toValue}
                onChange={(event) => setToValue(event.target.value)}
                variant="outlined"
                size="small"
                style={{ marginTop: 2 }}
              />
            </Box>
            <Box
              flexWrap="wrap"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Checkbox
                checked={nbConditionSelected}
                onChange={(e) => {
                  setNbConditionSelected(e.target.checked);
                }}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
              />
              <Box marginRight={1} style={{ fontSize: "14px" }}>
                Au moins{" "}
              </Box>
              <TextField
                type="number"
                required={nbConditionSelected}
                disabled={!nbConditionSelected}
                value={nbCondition}
                onChange={(event) => {
                  if (event.target.value === "" || +event.target.value >= 1) {
                    setNbCondition(event.target.value);
                  }
                }}
                variant="outlined"
                size="small"
                style={{ width: 70 }}
              />
              <Box marginLeft={1} style={{ fontSize: "14px" }}>
                {" "}
                fois de suite
              </Box>
            </Box>
          </form>
        );
      case 2:
        return (
          <form ref={step2Form}>
            <Typography
              style={{
                color: AppColor.textColor,
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              Comment recevoir les notifications :
            </Typography>
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              control={
                <Checkbox
                  checked={supportNotificationEmail}
                  onChange={(e) => {
                    setSupportNotificationEmail(e.target.checked);
                  }}
                  value="par email"
                  name="email"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                />
              }
              label="Par email"
            />
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              control={
                <Checkbox
                  checked={supportNotificationSms}
                  onChange={(e) => {
                    if (
                      !userData.acceptSms ||
                      userData.phoneNumber === null ||
                      !isPhoneNumberValid
                    ) {
                      setAcceptSmsOpen(true);
                    } else {
                      setSupportNotificationSms(e.target.checked);
                    }
                  }}
                  value="par sms"
                  name="sms"
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                />
              }
              label="Par sms"
            />

            <AcceptSmsDialog
              open={acceptSmsOpen}
              handleClose={() => {
                setAcceptSmsOpen(false);
              }}
              setSupportNotificationSms={setSupportNotificationSms}
              userData={userData}
              updateUserData={updateUserData}
            />

            {supportNotificationSms &&
              showSmsPreview(
                // TODO: Get sensor site name (not use view name)
                `${truncate(view.name)}, capteur ${
                  sensor.label
                    ? formatSmsText(sensor.label)
                    : "en position " + (sensor.sensor_index + 1)
                }: ${nbCondition} ${
                  Number(nbCondition) > 1 ? "valeurs" : "valeur"
                } ${
                  condition + conditionValue + formatSmsText(sensor.unit ?? "")
                } entre ${fromValue} et ${toValue}. Exemple 12:33 le 4: 10${formatSmsText(
                  sensor.unit ?? ""
                )}`
              )}
            <Typography
              style={{
                color: AppColor.textColor,
                fontWeight: "bold",
                fontSize: "13px",
                marginBottom: 7,
              }}
            >
              Délai minimal entre les notifications :
            </Typography>
            <Box display={"flex"} flexDirection={"row"}>
              <TextField
                type="number"
                required
                inputProps={{ min: 1 }}
                value={valueDelayNotification}
                onChange={(event) =>
                  setValueDelayNotification(event.target.value)
                }
                variant="outlined"
                size="small"
                style={{ marginRight: 5, width: 70 }}
              />
              <TextField
                select
                value={delayNotification}
                onChange={(event) => setDelayNotification(event.target.value)}
                variant="outlined"
                size="small"
              >
                <MenuItem key={"heures"} value={"heures"}>
                  heures
                </MenuItem>
                <MenuItem key={"jours"} value={"jours"}>
                  jours
                </MenuItem>
              </TextField>
            </Box>
          </form>
        );
      case 3:
        return (
          <Box>
            <Typography
              style={{
                color: AppColor.textColor,
                marginBottom: 5,
                fontSize: "14px",
              }}
            >
              Cette alarme se déclenchera si la{" "}
              <Blue>{{ value: "valeur" }[type]}</Blue> du capteur{" "}
              <Blue>{sensorLabel}</Blue> du view <Blue>{view.name}</Blue> est{" "}
              <Blue>{conditions[condition]}</Blue>{" "}
              {condition === "!="
                ? "de"
                : condition.includes("=")
                ? "à"
                : "que"}{" "}
              <Blue>
                {conditionValue} {sensor.unit ?? ""}
              </Blue>
              {nbConditionSelected && (
                <>
                  {" "}
                  au moins <Blue>{nbCondition}</Blue> fois de suite
                </>
              )}
              {fromToSelected && (
                <>
                  {" "}
                  entre <Blue>{fromValue}</Blue> et <Blue>{toValue}</Blue>
                </>
              )}
              . Vous recevrez une notification{" "}
              {notificationInfoText(
                supportNotificationEmail,
                supportNotificationSms
              )}
              <Blue>{valueDelayNotification}</Blue>{" "}
              <Blue>{delayNotification}</Blue> plus tard.
            </Typography>

            <Typography
              style={{
                marginBottom: 5,
                color: AppColor.textColorGrey,
                fontSize: "14px",
              }}
            >
              Vous pourrez désactiver l'alarme à tout moment depuis un lien dans
              les emails que vous recevrez.
            </Typography>
          </Box>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Dialog
      fullScreen={isMobile()}
      fullWidth={isMobile() ? undefined : true}
      maxWidth={isMobile() ? undefined : "md"}
      open={open}
      onClose={handleClose}
    >
      <Box padding={2}>
        <Box display="flex" flexDirection="row" flexWrap="wrap-reverse">
          <Typography
            style={{
              borderBottom: "2px solid " + AppColor.blueDataview,
              width: "auto",
              margin: "0 auto",
              flex: 1,
              marginRight: 15,
            }}
            variant="body1"
            gutterBottom
          >
            Alarme capteur {sensorLabel}
          </Typography>
          <CloseIcon onClick={handleClose} />
        </Box>

        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                      size="small"
                      variant="outlined"
                    >
                      PREC.
                    </Button>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          activeStep === steps.length - 1
                            ? handleValidate
                            : handleNext
                        }
                        className={classes.button}
                        size="small"
                      >
                        {activeStep === steps.length - 1
                          ? "VALIDER"
                          : "SUIVANT"}
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={() => setError(false)}
        >
          <Alert onClose={() => setError(false)} severity="error">
            Erreur, veuillez nous contacter ou réessayer
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
};
