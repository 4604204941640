import {
  Paper,
  Box,
  IconButton,
  TextField,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Menu,
} from "@material-ui/icons";
import { format, addDays } from "date-fns";
import React from "react";
import AppColor from "../../../../assets/Color";
import { isToday } from "date-fns";

interface GraphDialogControlsProps {
  handleClose: () => void;
  toggleLegendOpen: () => void;
  date: Date;
  setDate: (date: Date) => void;
  setRelativeValues: (v: boolean) => void;
  relativeValues: boolean;
  setAutoScale: (v: boolean) => void;
  autoScale: boolean;
}

const useStyles = makeStyles((theme) => ({
  input: {
    color: "white",
  },
  textField: {
    "& .MuiInputBase-input": {
      color: "#fff",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#fff8",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& input[type=date]::-webkit-calendar-picker-indicator": {
      color: "#fff",
    },
  },
  whiteCheckBox: {
    color: AppColor.white,
    "&$checked": {
      color: AppColor.white,
    },
  },
}));

export const GraphDialogControls = ({
  date,
  handleClose,
  toggleLegendOpen,
  setDate,
  setRelativeValues,
  relativeValues,
  setAutoScale,
  autoScale,
}: GraphDialogControlsProps) => {
  const classes = useStyles();

  return (
    <Paper
      style={{
        backgroundColor: AppColor.blueDataview,
        display: "flex",
        justifyContent: "flex-end",
        padding: 10,
        alignItems: "center",
        borderRadius: 0,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            className={classes.whiteCheckBox}
            checked={relativeValues}
            onChange={(e) => setRelativeValues(e.target.checked)}
            name="%"
            color="default"
          />
        }
        style={{ color: "white", fontWeight: "bold" }}
        label="%"
      />

      <FormControlLabel
        control={
          <Checkbox
            className={classes.whiteCheckBox}
            checked={autoScale}
            onChange={(e) => setAutoScale(e.target.checked)}
            name="%"
            color="default"
          />
        }
        style={{ color: "white", fontWeight: "bold" }}
        label="Auto scale"
      />

      <Box
        className={"calendar-graph"}
        flexGrow={1}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <IconButton
          size="small"
          onClick={() => setDate(addDays(date, -1))}
          style={{ marginRight: 15 }}
        >
          <KeyboardArrowLeft
            fontSize="default"
            style={{ color: AppColor.white }}
          />
        </IconButton>
        <TextField
          className={classes.textField}
          type="date"
          value={format(date, "yyyy-MM-dd")}
          onChange={(e) => setDate(new Date(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            className: classes.input,
            max: format(new Date(), "yyyy-MM-dd"),
          }}
        />
        <IconButton
          size="small"
          onClick={() => setDate(addDays(date, 1))}
          style={{ marginLeft: 15, opacity: isToday(date) ? 0 : 1 }}
          disabled={isToday(date)}
        >
          <KeyboardArrowRight
            fontSize="default"
            style={{ color: AppColor.white }}
          />
        </IconButton>
      </Box>
      <Box>
        <Button
          color="inherit"
          variant="outlined"
          size="small"
          onClick={handleClose}
          style={{ marginRight: 15, color: "white" }}
        >
          FERMER
        </Button>
        <IconButton size="small" onClick={toggleLegendOpen}>
          <Menu fontSize="default" style={{ color: AppColor.white }} />
        </IconButton>
      </Box>
    </Paper>
  );
};
