import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import React, { FormEvent, useEffect, useState } from "react";
import AppColor from "../../assets/Color";
import { usersGetTokenInfo, usersSetPassword } from "../../repository/network";
import PasswordStrengthBar from "react-password-strength-bar";
import { PasswordField } from "material-ui-password";

const useStyles = makeStyles({
  title: {
    fontWeight: "bold",
    borderBottom: "3px solid " + AppColor.blueDataview,
    width: "auto",
    margin: "0 auto",
    marginBottom: 20,
  },
  textField: {
    width: "100%",
    marginBottom: 10,
  },
});

export const InviteUserView = () => {
  const [pw, setPw] = useState<string>("");
  const [pw2, setPw2] = useState<string>("");
  const [currentScore, setCurrentScore] = React.useState<number | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [invalidToken, setInvalidToken] = React.useState(false);
  const [passwordsMismatch, setPasswordsMismatch] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<null | {
    firstName: string | null;
    lastName: string | null;
    email: string;
  }>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") ?? "";

  useEffect(() => {
    usersGetTokenInfo(token)
      .then(setUserInfo)
      .catch((e: Error) => {
        setInvalidToken(true);
      });
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (pw.length < 8 || pw !== pw2) {
      setErrorMessage("Votre mot de passe doit faire au moins 8 caractères");
      return;
    }
    if (currentScore !== null && currentScore <= 1) {
      setErrorMessage("Erreur, veuillez saisir un mot de passe plus sécurisé");
      return;
    }
    const res = await usersSetPassword({
      inviteToken: token,
      password: pw,
    });

    if (res.status === 200) {
      window.location.href = "/login?accountCreated=1";
      return;
    } else {
      setErrorMessage("Erreur, veuillez nous contacter");
      return;
    }
  };

  const classes = useStyles();

  if (invalidToken) {
    return (
      <>
        <Alert severity="warning">
          Ce lien de création de compte est invalide ou déjà utilisé.&nbsp;
          <a href="/login">
            <b>Aller à la page de connexion</b>
          </a>
          &nbsp;ou&nbsp;
          <a href="mailto:contact@dataview.fr">Contacter le support</a>
        </Alert>
      </>
    );
  }

  if (userInfo === null) {
    return <LinearProgress />;
  }

  return (
    <Box
      className="login-bg"
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Card
        style={{
          padding: 30,
          margin: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/logo-web-transparent.png"
          style={{ maxWidth: "70%", marginBottom: 20 }}
        />
        <Typography
          className={classes.title}
          variant="h5"
          component="h5"
          gutterBottom
        >
          Création de compte
        </Typography>

        <form onSubmit={onSubmit} autoComplete="on">
          <Typography variant="body1" gutterBottom>
            Bonjour {userInfo.firstName} {userInfo.lastName}, vous avez été
            invité·e sur Dataview !
          </Typography>

          <Alert severity="info">
            Votre identifiant client sera <b>{userInfo.email}</b>
          </Alert>
          <br />
          <br />
          {/* to save password in browser for login */}
          <input
            name="email"
            type="email"
            value={userInfo.email}
            style={{ display: "none" }}
          />
          <PasswordField
            className={classes.textField}
            label="Choisissez un mot de passe"
            variant="outlined"
            value={pw}
            onChange={(e) => setPw(e.target.value)}
            required
            autoFocus
            fullWidth
          />
          <PasswordStrengthBar
            password={pw}
            scoreWords={[
              "Pas sécurisé",
              "Pas sécurisé",
              "Moyen",
              "Suffisant",
              "Sécurisé",
            ]}
            shortScoreWord={"Veuillez saisir un mot de passe sécurisé"}
            onChangeScore={(score) => setCurrentScore(score)}
            style={{ marginBottom: "15px" }}
          />
          <TextField
            onBlur={() => {
              const match = pw.length > 0 && pw2.length > 0 && pw !== pw2;
              setPasswordsMismatch(match);
            }}
            onKeyUp={() => {
              if (passwordsMismatch && pw === pw2) setPasswordsMismatch(false);
            }}
            className={classes.textField}
            type="password"
            label="Confirmez le mot de passe"
            variant="outlined"
            value={pw2}
            onChange={(e) => setPw2(e.target.value)}
            error={passwordsMismatch}
            helperText={
              passwordsMismatch ? "Les mots de passe sont différents" : null
            }
            required
            fullWidth
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox required name="cgu" />}
              label={
                <>
                  J'accepte les{" "}
                  <a href="/cgu" target="_blank">
                    CGU
                  </a>
                </>
              }
            />
          </FormGroup>
          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            Créer mon compte <ArrowForward />
          </Button>
        </form>
      </Card>
      <Snackbar
        open={errorMessage !== null}
        autoHideDuration={10000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
