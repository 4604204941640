import { Button, Collapse, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { PropsWithChildren, ReactFragment, useState } from "react";

export default function CustomCollapse(
  props: PropsWithChildren<{
    title: ReactFragment;
  }>
) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Typography color="textSecondary" component="h6">
        <Button
          size="small"
          onClick={() => setVisible(!visible)}
          startIcon={visible ? <ExpandLess /> : <ExpandMore />}
        >
          {props.title}
        </Button>
      </Typography>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </>
  );
}
