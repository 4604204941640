import React, { FormEvent, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { resetPassword } from "../../repository/network";
import Alert from "@material-ui/lab/Alert";

export default function ResetPasswordDialog({
  open,
  handleClose,
  currentEmail,
}: {
  open: boolean;
  currentEmail: string;
  handleClose: () => void;
}) {
  const [email, setEmail] = React.useState(currentEmail);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  useEffect(() => setEmail(currentEmail), [currentEmail]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await resetPassword({
      email: email,
    });

    if (res.status === 200) {
      window.location.href = "/login?emailSent=1";
      return;
    } else if (res.status === 400) {
      setErrorMessage(
        "Erreur, cette adresse n'est pas associée à un compte client."
      );
      return;
    } else if (res.status === 429) {
      setErrorMessage(
        "Merci d'attendre 15 minutes avant votre prochaine demande. Si toutefois vous n'avez pas reçu le mail, veuillez contacter le support."
      );
    } else {
      setErrorMessage(
        "Un problème technique est survenu, veuillez nous contacter"
      );
      return;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      style={{ textAlign: "center" }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">
          Réinitialiser le mot de passe
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Afin de récuperer votre mot de passe, veuillez saisir votre adresse
            e-mail.
          </DialogContentText>
          {errorMessage !== null && (
            <Alert onClose={() => setErrorMessage(null)} severity="error">
              {errorMessage}
            </Alert>
          )}
          <TextField
            autoFocus
            value={email}
            autoComplete="on"
            type="email"
            label="Email"
            variant="outlined"
            style={{ marginTop: 30 }}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button color="primary" type="submit">
            Envoyer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
