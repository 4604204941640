import { red } from "@material-ui/core/colors";
import { Notifications, NotificationsActive } from "@material-ui/icons";
import React from "react";
import AppColor from "../../../assets/Color";
import { AlarmDefinitionWithEvents } from "../../../model/types";

export function ConditionalAlarmBadge({
  alarmDefinitions,
}: {
  alarmDefinitions: AlarmDefinitionWithEvents[] | undefined;
}) {
  if (alarmDefinitions === undefined || alarmDefinitions.length === 0) {
    return <></>;
  }

  const unacknowledgedEvents = alarmDefinitions.filter(
    (alarm) => alarm.unacknowledgedEvents.length !== 0
  ).length;

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: -10,
          right: -10,
        }}
      >
        {unacknowledgedEvents !== 0 ? (
          <NotificationsActive
            style={{
              fontSize: "2.5em",
              color: red[500],
            }}
          />
        ) : (
          <Notifications
            style={{
              fontSize: "2.5em",
              color: AppColor.blueDataview,
            }}
          />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          fontWeight: unacknowledgedEvents !== 0 ? "bold" : "inherit",
          color: "white",
          width: 20,
          textAlign: "center",
          top: "-2px",
          right: "-6px",
        }}
      >
        {unacknowledgedEvents !== 0
          ? unacknowledgedEvents
          : alarmDefinitions.length}
      </div>
    </>
  );
}
