import {
  Box,
  TextField,
  Collapse,
  makeStyles,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useEffect, useState } from "react";
import { ShowNotesComponent } from "./notes/ShowNotesComponent";
import {
  deleteUserNote,
  updateNoteRead,
  userGetNotesBySensorId,
  userSetNotes,
} from "../../../repository/network";
import { Notes } from "../../../model/types";
import { StringTooltipParams } from "./StringTooltip";

const useStyles = makeStyles({
  textField: {
    "& .MuiInputBase-root": {
      height: "100%",
      width: "100%",
    },
  },
  showNotesContainer: {
    width: 220,
    height: 220,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  addNotesButton: {
    width: "90%",
    marginRight: 3,
    marginBottom: 5,
    color: "white",
    fontSize: "12px",
  },
});

export function TooltipNotesTab(props: StringTooltipParams) {
  const { sensor } = props;
  const [notes, setNotes] = useState<Notes[]>([]);
  const [showNotes, setShowNotes] = useState(false);
  const [noteContent, setNoteContent] = useState("");

  const refresh = () => {
    userGetNotesBySensorId(sensor.id!).then((data) => {
      setNotes(data);
    });
    updateNoteRead({ sensorId: sensor.id! }).catch(console.error);
  };

  const handleClick = () => {
    if (noteContent.length > 0) {
      setNoteContent("");
    }
    setShowNotes(!showNotes);
  };

  const deleteNote = (noteId: number) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?")) {
      deleteUserNote({ noteId: noteId }).then(refresh).catch(console.error);
    }
  };

  const onSubmit = () => {
    if (noteContent.length > 0) {
      userSetNotes({
        content: noteContent,
        siteId: sensor.site_id!,
        sensorId: sensor.id,
      })
        .then(() => {
          refresh();
          setShowNotes(!showNotes);
        })
        .catch(console.error);
    }
  };

  useEffect(refresh, []);

  const classes = useStyles();

  return (
    <div
      className="note-bg"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height={"100%"}
        width={"100%"}
      >
        <Box display="flex" flexGrow={1} width={"100%"}>
          {showNotes ? (
            <TextField
              className={classes.textField}
              label="Rédiger votre note"
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
              multiline
              variant="filled"
              size="small"
              margin="none"
              fullWidth
              inputProps={{
                style: {
                  fontSize: "13px",
                  lineHeight: "13px",
                  height: "100%",
                  width: "100%",
                  padding: 0,
                  wordWrap: "break-word",
                },
              }}
            />
          ) : (
            <Box className={classes.showNotesContainer}>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              ></div>
              <Button
                className={classes.addNotesButton}
                size={"small"}
                variant="contained"
                color={"primary"}
                onClick={handleClick}
              >
                Ajouter une note
              </Button>
              <ShowNotesComponent deleteNote={deleteNote} notes={notes} />
            </Box>
          )}
        </Box>

        <Collapse
          in={showNotes}
          timeout="auto"
          unmountOnExit
          style={{ width: "100%" }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <IconButton size={"medium"} onClick={handleClick}>
              <CancelIcon color="error" />
            </IconButton>
            <IconButton size={"medium"} onClick={onSubmit}>
              <SendIcon color="primary" />
            </IconButton>
          </div>
        </Collapse>
      </Box>
    </div>
  );
}
