import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Update } from "@material-ui/icons";
import { addDays, differenceInCalendarDays, format } from "date-fns";
import _ from "lodash";
import React from "react";
import AppColor from "../../assets/Color";
import { ServerReturnedLogs, TimestampAtStart, View } from "../../model/types";
import { isMobile } from "../../utils/util";

export const SiteViewSettings = ({
  loading,
  filteredData,
  views,
  viewId,
  setViewId,
  multiSiteView,
  dataTimestamps,
  deltaDay,
  realTime,
  relativeValues,
  setRelativeValues,
  setRealTime,
  setLoading,
  setDeltaDay,
  setShouldSetTimestampAtStart,
}: {
  loading: boolean;
  filteredData: ServerReturnedLogs | null;
  views: View[];
  viewId: number;
  setViewId: (id: number) => void;
  multiSiteView: boolean;
  dataTimestamps: number[] | null;
  deltaDay: number;
  realTime: boolean;
  relativeValues: boolean;
  setRelativeValues: (id: boolean) => void;
  setRealTime: (id: boolean) => void;
  setLoading: (id: boolean) => void;
  setDeltaDay: (id: number) => void;
  setShouldSetTimestampAtStart: (id: TimestampAtStart) => void;
}) => {
  const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setViewId(e.target.value as number);
  };

  // Active site --> has a log in on of the timestamps
  let activeSiteIds: number[] = [];
  if (filteredData !== null) {
    const timestamps = Object.keys(filteredData);
    activeSiteIds = _.uniq(
      _.flatten(timestamps.map((t) => Object.keys(filteredData[t])))
    ).map(Number);
  }

  const handleDateChange = (date: string) => {
    const diffDay = differenceInCalendarDays(new Date(date), new Date());
    console.log("d", diffDay, deltaDay);
    if (diffDay !== deltaDay) {
      setShouldSetTimestampAtStart("mid");
      setLoading(true);
      setDeltaDay(diffDay);
      setRealTime(false);
    }
  };

  return (
    <Box
      flexDirection="row"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      style={{ color: AppColor.textColor }}
    >
      <Box mx={1}>
        {multiSiteView ? null : (
          <Select value={viewId} onChange={handleSelectChange}>
            {views.map((view: View) => (
              <MenuItem value={view.id} key={view.id}>
                {view.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>

      {!isMobile() ? (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box mx={1}>Valeurs</Box>
          <Select
            value={Number(relativeValues)}
            onChange={(e) => setRelativeValues(Boolean(e.target.value))}
            displayEmpty
          >
            <MenuItem value={1}>relatives</MenuItem>
            <MenuItem value={0}>instantanées</MenuItem>
          </Select>
          &nbsp;
          {realTime && dataTimestamps !== null && !loading ? (
            <Box>en temps réel</Box>
          ) : null}
        </Box>
      ) : null}

      <Box mr={1} display="flex" flexDirection="row" alignItems="center">
        {!isMobile() ? <Box mx={1}>du</Box> : null}
        <TextField
          disabled={loading}
          id="date"
          type="date"
          value={format(addDays(new Date(), deltaDay), "yyyy-MM-dd")}
          onChange={(e) => {
            handleDateChange(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: "2019-11-30",
            max: format(new Date(), "yyyy-MM-dd"),
          }}
          required
        />
      </Box>

      {dataTimestamps !== null &&
      realTime &&
      dataTimestamps !== null &&
      !loading ? (
        !isMobile() ? (
          <Box>
            - actualisé à&nbsp;
            {format(new Date(Math.max(...dataTimestamps)), "HH:mm")}
          </Box>
        ) : null
      ) : isMobile() ? ( // Return to real time mode for mobile
        <Tooltip title="Retour mode temps réel">
          <IconButton
            size="small"
            disabled={loading}
            onClick={() => {
              setRealTime(true);
            }}
          >
            <Update />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="outlined"
          size="small"
          disabled={loading}
          onClick={() => {
            setRealTime(true);
          }}
        >
          Retour mode temps réel
        </Button>
      )}
    </Box>
  );
};
