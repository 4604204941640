import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormHelperText,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Block, ContactSupport, ExitToApp } from "@material-ui/icons";
import ColorHash from "color-hash-ts";
import React from "react";
import { UserType } from "../../../model/types";
import { logOut } from "../../../utils/util";
import CustomCollapse from "../CustomCollapse";
import UserEditInfo from "./UserEditInfo";

const useStyles = makeStyles((theme) => ({
  dangerButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}));

type UserSettingsViewProps = {
  loading: boolean;
  userData: UserType;
  requestUserUpdate: (user: UserType) => void;
  requestPasswordReset: () => void;
};

export default function UserSettingsView({
  loading,
  userData,
  requestUserUpdate,
  requestPasswordReset,
}: UserSettingsViewProps) {
  const classes = useStyles();
  return (
    <Card style={{ maxWidth: 400 }}>
      <CardHeader
        avatar={
          <Avatar
            style={{ backgroundColor: new ColorHash().hex(userData.firstName) }}
          />
        }
        title={`${userData.firstName} ${userData.lastName}`}
        subheader={userData.email}
        action={
          <IconButton onClick={logOut}>
            <ExitToApp />
          </IconButton>
        }
      />
      <CardContent>
        <CustomCollapse title={"Informations personnelles"}>
          <Box p={2}>
            <UserEditInfo
              userData={userData}
              requestUserUpdate={requestUserUpdate}
              loading={loading}
            />
          </Box>
        </CustomCollapse>
        <CustomCollapse title={"Mot de passe"}>
          <Box p={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={requestPasswordReset}
            >
              Modifier le mot de passe
            </Button>
            <FormHelperText>
              Assurez-vous que votre adresse <b>{userData.email}</b> est
              correcte
              <br />
              Un lien vous sera envoyé pour redéfinir votre mot de passe
            </FormHelperText>
          </Box>
        </CustomCollapse>
        {userData.phoneNumber !== null && (
          <CustomCollapse
            title={
              <span style={userData.acceptSms ? undefined : { color: "red" }}>
                Paramètres de notification
              </span>
            }
          >
            <Box p={2}>
              <Button
                size="small"
                variant="outlined"
                disabled={loading}
                startIcon={userData.acceptSms ? <Block /> : undefined}
                onClick={() =>
                  requestUserUpdate({
                    ...userData,
                    acceptSms: !userData.acceptSms,
                  })
                }
                className={
                  userData.acceptSms ? classes.dangerButton : undefined
                }
              >
                {loading && <CircularProgress size={20} color="secondary" />}
                {userData.acceptSms
                  ? "Désactiver les alertes par SMS"
                  : "Réactiver les alertes par SMS"}
              </Button>
              {userData.acceptSms && (
                <FormHelperText>
                  Bloque temporairement <b>tous</b> les SMS d'alerte sur vos
                  capteurs. Les alarmes ne seront pas supprimées mais ne
                  fonctionneront plus.
                </FormHelperText>
              )}
            </Box>
          </CustomCollapse>
        )}
        <CustomCollapse title={"Support"}>
          <Box p={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              href="mailto:contact@dataview.fr"
              startIcon={<ContactSupport />}
            >
              contact@dataview.fr
            </Button>
            <FormHelperText>
              N'hésitez pas à nous contacter pour toute question, problème ou
              suggestion
            </FormHelperText>
          </Box>
        </CustomCollapse>
      </CardContent>
    </Card>
  );
}
