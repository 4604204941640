import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  IconButton,
  makeStyles,
  Popover,
  TextField,
} from "@material-ui/core";
import {
  Build,
  Close,
  Description,
  LocalOfferOutlined,
  Notifications,
  Schedule,
  ShowChart,
} from "@material-ui/icons";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Sensor,
  TooltipStringInfo,
  UserType,
  View,
} from "../../../model/types";
import { userGetNotesBySensorId } from "../../../repository/network";
import { GraphDialog } from "./graph/GraphDialog";
import { TooltipAlarmTab } from "./TooltipAlarmTab";
import { TooltipConfigTab } from "./TooltipConfigTab";
import { TooltipGraphTab } from "./TooltipGraphTab";
import { TooltipNotesTab } from "./TooltipNotesTab";

export interface StringTooltipParams {
  sensors: Sensor[];
  sensor: Sensor;
  setSensor: (s: Sensor, persist?: boolean) => void;
  relativeValues: boolean;
  tooltipStringInfo: TooltipStringInfo;
  setAnchorEl: (x: Element | null) => void;
  view: View;
  viewSensorsId: string[];
  userData: UserType;
  updateUserData: (x: UserType) => Promise<void>;
}

const useStyles = makeStyles({
  bottomNav: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      padding: 0,
    },
  },
});

export const StringTooltip = (props: StringTooltipParams) => {
  const classes = useStyles();

  const {
    view,
    viewSensorsId,
    relativeValues,
    sensors,
    sensor,
    setSensor,
    tooltipStringInfo,
    setAnchorEl,
  } = props;

  const [graphIsOpen, setGraphOpen] = React.useState(false);
  const handleClickGraph = () => {
    setGraphOpen(true);
  };

  const [currentTab, setCurrentTab] = React.useState(0);
  const x = tooltipStringInfo.positionX ?? 0;
  const y = tooltipStringInfo.positionY ?? 0;

  const handleClickClose = () => {
    setCurrentTab(0);
    setAnchorEl(null);
  };

  const [numbersOfNotes, setNumbersOfNotes] = useState<number>(0);

  useEffect(() => {
    if (sensor.id != undefined) {
      userGetNotesBySensorId(sensor.id!)
        .then((data) => {
          setNumbersOfNotes(data.length);
        })
        .catch(console.error);
    }
  }, [sensor]);
  return (
    <Popover
      anchorReference={
        tooltipStringInfo.positionX ? "anchorPosition" : "anchorEl"
      }
      anchorEl={tooltipStringInfo.anchorEl}
      anchorPosition={{
        top: y,
        left: x,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={tooltipStringInfo.anchorEl !== null}
      onClose={() => handleClickClose()}
    >
      <div style={{ width: 200, padding: 10, paddingBottom: 0 }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width={"100%"}
        >
          <span style={{ flexGrow: 1, fontWeight: "bold", fontSize: "13px" }}>
            <Schedule
              fontSize="small"
              style={{ verticalAlign: "middle", marginRight: 5 }}
            />
            {format(new Date(tooltipStringInfo.timestamp), "dd/MM/yy HH:mm")}
          </span>

          <Box>
            <IconButton size="small" onClick={() => handleClickClose()}>
              <Close fontSize="default" />
            </IconButton>
          </Box>
        </Box>

        <LocalOfferOutlined fontSize="small" />
        <TextField
          value={sensor.label}
          placeholder={`SN ${tooltipStringInfo.inverterSN}, String ${tooltipStringInfo.stringIndex}`}
          onChange={(e) =>
            setSensor({
              ...sensor,
              label: e.currentTarget.value || undefined,
            })
          }
          size="small"
          inputProps={{
            style: {
              fontSize: "14px",
              lineHeight: "12px",
              width: 150,
              padding: 5,
              margin: 0,
            },
          }}
        />

        <div
          style={{
            height: 200,
            overflowX: "hidden",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {currentTab === 0 ? (
            <TooltipNotesTab {...props} />
          ) : currentTab === 1 ? (
            <TooltipConfigTab {...props} />
          ) : currentTab === 2 ? (
            <TooltipAlarmTab {...props} />
          ) : currentTab === 3 ? (
            <TooltipGraphTab {...props} />
          ) : (
            <></>
          )}
        </div>
        <BottomNavigation
          className={classes.bottomNav}
          value={currentTab}
          onChange={(e, tab) => {
            if (tab === 3) {
              handleClickGraph();
            } else {
              setCurrentTab(tab);
            }
          }}
          style={{
            margin: "0 -10px",
            background: "#eee",
          }}
          color="primary"
          showLabels
        >
          <BottomNavigationAction
            label="Notes"
            icon={
              <Badge
                color="primary"
                variant={numbersOfNotes > 0 ? "dot" : undefined}
              >
                <Description />
              </Badge>
            }
          />
          <BottomNavigationAction label="Config" icon={<Build />} />
          <BottomNavigationAction
            label="Alertes"
            icon={
              <Badge
                color="primary"
                variant={
                  sensor.alarmDefinitions !== undefined &&
                  sensor.alarmDefinitions.length > 0
                    ? "dot"
                    : undefined
                }
              >
                <Notifications />
              </Badge>
            }
          />
          <BottomNavigationAction label="Graph" icon={<ShowChart />} />
        </BottomNavigation>
      </div>
      {graphIsOpen && (
        <GraphDialog
          sensors={sensors}
          open={graphIsOpen}
          handleClose={() => setGraphOpen(false)}
          view={view}
          viewSensorsId={viewSensorsId}
          relativeValues={relativeValues}
          sensor={sensor}
          initialDate={new Date(tooltipStringInfo.timestamp)}
        />
      )}
    </Popover>
  );
};
