import { addMinutes, startOfDay } from "date-fns";
import _ from "lodash";
import { Sensor, ServerReturnedLog, WeatherData } from "../model/types";

export interface LocalStorageSettings {
  multiSiteView: boolean;
  relativeValues: boolean;
  displayTimeBounds: [number, number];
}

export function deviceOrientation(): string {
  // https://github.com/zeroseven/react-screen-orientation/blob/develop/src/index.js
  let orientation = "portrait";
  let type = "primary";
  let angle = 0;
  if (window.orientation) {
    angle = Number(window.orientation);
    orientation = Math.abs(angle) === 90 ? "landscape" : "portrait";
  }

  if (window.screen.orientation) {
    [orientation, type] = window.screen.orientation.type.split("-");
  }

  return orientation;
}

function HSLToHex(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
}

export const hexToHSL = (H: string) => {
  // Convert hex to RGB first
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export const hsl = (hh: number, ss: number, ll: number) =>
  "hsl(" + (hh % 360) + "," + ss + "%," + ll + "%)";

const getRelativeColoringColor = (v: number) => {
  const clamped = Math.max(Math.min(v, 1), 0);
  return HSLToHex(clamped * 130, 100 - clamped * 10, 50 - clamped * 10);
};

export const getStringColor = (
  disabled: boolean,
  relativeColoring: boolean,
  v: number | null
) =>
  disabled
    ? "#666666"
    : v === null
    ? "#ccc"
    : Number.isNaN(v)
    ? "#000"
    : relativeColoring
    ? getRelativeColoringColor(v)
    : presentValues(v) === "0"
    ? "#ff0000"
    : "#00b050";

export const presentValues = (v: number): string => {
  const str = v.toString();
  const s = str.includes(".") ? v.toFixed(1) : str;
  return (s === "0,0" ? "0" : s).replace(".", ",");
};

export const presentValuesRelative = (v: number): string =>
  v === Number.MAX_VALUE
    ? "MAX"
    : v === Number.MIN_VALUE
    ? "MIN"
    : Math.round(+Math.max(0, v * 100).toPrecision(2)) + "%";

export const isMobile = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const getDayBounds = (
  d: Date,
  lowerBoundMinutes: number,
  upperBoundMinutes: number
): [Date, Date] => {
  const start = startOfDay(d);
  return [
    addMinutes(start, lowerBoundMinutes),
    addMinutes(start, upperBoundMinutes),
  ];
};

export function minutesFromStartOfDayToHHMM(x: number): string {
  const time = addMinutes(startOfDay(new Date()), x);
  return (
    String(time.getHours()).padStart(2, "0") +
    ":" +
    String(time.getMinutes()).padStart(2, "0")
  );
}

export function startOfDayToHHMMFromMinutes(x: string): number {
  const [h, m] = x.split(":").map(Number);
  return h * 60 + m;
}

export const splitLayout = (nInv: number, nStrPerInv: number, max: number) => {
  const splitIn = (nInv * nStrPerInv) / max;
  if (splitIn <= 1) {
    return [nInv];
  }
  const invertersByRow = Math.ceil(max / nStrPerInv);
  return _.range(0, Math.ceil(splitIn)).map((i) =>
    splitIn % 1 !== 0 && i === Math.ceil(splitIn) - 1
      ? invertersByRow - 1
      : invertersByRow
  );
};

export const isMultisiteFeatureFlagEnabled = () =>
  localStorage.getItem("FEATURE_MULTISITE") != null;

export const isPhoneNumberValid = (phoneNumber: string | undefined) =>
  phoneNumber !== undefined &&
  /^\d{9}$/.test(phoneNumber) &&
  /^6|7/.test(phoneNumber); // tel portable

export const logOut = () => {
  if (window.confirm("Fermer votre session ?")) {
    localStorage.clear();
    window.location.href = "/login";
  }
};

// console.log(_.isEqual(splitLayout(1, 10, 10), [1]));
// console.log(_.isEqual(splitLayout(2, 10, 10), [1, 1]));
// console.log(_.isEqual(splitLayout(2, 5, 10), [2]));
// console.log(_.isEqual(splitLayout(4, 5, 10), [2, 2]));
// console.log(_.isEqual(splitLayout(3, 5, 10), [2, 1]));
// console.log(_.isEqual(splitLayout(3, 5, 5), [1, 1, 1]));
// console.log(_.isEqual(splitLayout(3, 5, 15), [3]));
// console.log(_.isEqual(splitLayout(5, 5, 15), [3, 2]));
// console.log(_.isEqual(splitLayout(5, 5, 20), [4, 3]));

export const extractWeatherData = (
  weatherValues: ServerReturnedLog["values"],
  sensorsById: _.Dictionary<Sensor>
): WeatherData => {
  let weatherData: WeatherData = {};
  _.keys(weatherValues).forEach((sensorId) => {
    const label = sensorsById[sensorId]?.label;
    const labels = ["temp", "iconNum", "sunrise", "sunset"]; //TODO: Remove duplicate of WeatherData interface keys
    if (label && labels.includes(label)) {
      weatherData[label as keyof WeatherData] =
        weatherValues[parseInt(sensorId)];
    }
  });
  return weatherData;
};
