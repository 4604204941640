import React, { Fragment } from "react";
import Slider from "@material-ui/core/Slider";
import { Tooltip } from "@material-ui/core";
import { WeatherData } from "../../model/types";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AppColor from "../../assets/Color";
import { format, isSameDay } from "date-fns";

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    background:
      "radial-gradient(rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 70%)",
    color: AppColor.textColor,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    textShadow: "0 -1px 0 white",
    margin: "2px auto",
  },
}))(Tooltip);

const LeanTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    border: "none",
    margin: 0,
    padding: 0,
    background: "none",
    color: AppColor.textColor,
    textAlign: "center",
  },
}))(Tooltip);

function getValue(timestamp: number) {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) return "...";
  return (
    <Fragment>
      {isSameDay(date, new Date()) ? "Ce jour" : format(date, "dd/MM/yyyy")}
      <br />
      {format(date, "HH:mm")}
    </Fragment>
  );
}

function ValueLabelComponent(props: object) {
  const { children, open, value } = props as any;

  const iconName = value.weatherData?.iconNum
    ? value.weatherData.iconNum.toString() + (value.valueIsInDay ? "" : "n")
    : "";

  return (
    <>
      {value.weatherData?.temp !== undefined && (
        <CustomTooltip
          style={{ zIndex: 3 }}
          open={open}
          enterTouchDelay={0}
          placement="top"
          title={
            <Fragment>
              {iconName && (
                <img src={`/weather/${iconName}.png`} width="30" height="30" />
              )}
              <div style={{ textAlign: "center", fontSize: "1.2em" }}>
                {`${value.weatherData.temp.toFixed(1)}°C`}
              </div>
            </Fragment>
          }
        >
          {children}
        </CustomTooltip>
      )}

      <LeanTooltip
        placement="bottom"
        open={open}
        enterTouchDelay={0}
        title={getValue(value.value)}
      >
        {children}
      </LeanTooltip>
    </>
  );
}

export default function TimeSlider({
  data,
  onChange,
  onDayOverflow,
  value,
  weatherData,
}: {
  data: number[] | null;
  onChange: any;
  onDayOverflow: (delta: 1 | -1) => void;
  value: number;
  weatherData?: WeatherData;
}) {
  if (data === null) {
    return <></>;
  }
  const [min, max] = [Math.min(...data), Math.max(...data)];
  const diff = (max - min) / 1000;

  let classes = {};
  let valueIsInDay: boolean;

  if (weatherData !== undefined) {
    const sunrisePercentage = weatherData.sunrise
      ? ((weatherData.sunrise - min / 1000) / diff) * 100
      : 0;

    const sunsetPercentage = weatherData.sunset
      ? ((weatherData.sunset - min / 1000) / diff) * 100
      : 100;

    const valuePercentage = ((value - min) / (diff * 1000)) * 100;

    valueIsInDay =
      valuePercentage >= sunrisePercentage &&
      valuePercentage <= sunsetPercentage;

    classes = makeStyles((theme) => ({
      rail: {
        background: `linear-gradient(to right, darkblue 0 ${sunrisePercentage}%, ${AppColor.yellow} ${sunrisePercentage}% ${sunsetPercentage}%, darkblue ${sunsetPercentage}% 100%);`,
        opacity: 1,
      },
      track: {
        display: "none",
      },
      mark: {
        background: "white",
      },
      thumb: {
        backgroundColor: valueIsInDay ? AppColor.yellow : "darkblue",
        transform: "scale(1.5)",
      },
    }))();
  }

  return data.length ? (
    <Slider
      classes={classes}
      onWheel={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (data.length === 0) return;
        const delta = Math.sign(e.deltaY);
        if (data.indexOf(value) === -1) {
          return console.error(
            "Value is not in the data !!!",
            new Date(value),
            data.map((timestamp) => new Date(timestamp))
          );
        }
        const nextIndex = data.indexOf(value) + delta;

        if (nextIndex > data.length - 1) {
          onDayOverflow(1);
        } else if (nextIndex < 0) {
          onDayOverflow(-1);
        } else {
          onChange(null, data[nextIndex]);
        }
      }}
      onChange={onChange}
      step={null}
      valueLabelFormat={(x: number) => ({
        value: x,
        weatherData,
        valueIsInDay,
      })}
      ValueLabelComponent={ValueLabelComponent}
      value={value}
      min={Math.min(...data)}
      max={Math.max(...data)}
      marks={(() => {
        return data.map((i: number) => ({
          value: i,
        }));
      })()}
      valueLabelDisplay="on"
    />
  ) : (
    <></>
  );
}
