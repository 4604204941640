import { Box, Dialog, List, ListItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import distinctColors from "distinct-colors";
import _ from "lodash";
import React, { useEffect } from "react";
import AppColor from "../../../../assets/Color";
import { useInterval, useWindowDimensions } from "../../../../hooks/hooks";
import { GraphSensorElement, Sensor, View } from "../../../../model/types";
import { getGraphLogs } from "../../../../repository/network";
import { extractWeatherData, isMobile } from "../../../../utils/util";
import { Graph } from "./Graph";
import "./GraphDialog.css";
import { GraphDialogControls } from "./GraphDialogControls";
import { PleaseRotatePhone } from "./PleaseRotatePhone";

interface GraphDialogParams {
  open: boolean;
  handleClose: () => void;
  view: View;
  viewSensorsId: string[];
  relativeValues: boolean;
  sensor: Sensor;
  sensors: Sensor[];
  initialDate: Date;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "90%",
    maxHeight: "90%",
    minWidth: "90%",
    maxWidth: "90%",
  },
}));

export const GraphDialog = ({
  handleClose,
  open,
  view,
  viewSensorsId,
  relativeValues,
  sensor,
  sensors,
  initialDate,
}: GraphDialogParams) => {
  const classes = useStyles();

  const isMobileSafari =
    /safari/i.test(navigator.userAgent) &&
    /ipod|ipad|iphone/i.test(navigator.userAgent);
  const dimensions = useWindowDimensions();
  const [legendIsClose, setLegendClose] = React.useState(false);
  const [forceShowInPortrait, setForceShowInPortrait] = React.useState(false);
  const isPortrait = () => {
    return dimensions.width < dimensions.height;
  };

  const [date, setDate] = React.useState<Date>(initialDate);

  const [data, setData] = React.useState<Array<Record<string, any>>>([]);

  const [sensorsList, setSensorsList] = React.useState<GraphSensorElement[]>(
    []
  );

  const [isRelativeValues, setRelativeValues] = React.useState(relativeValues);
  const [autoScale, setAutoScale] = React.useState(true);

  const getData = (keepSensorsList = true) => {
    getGraphLogs(view.id, date, isRelativeValues).then((data) => {
      if (!_.isEqual(data, {})) {
        var newData: Record<string, any>[] = [];
        var newSensorList: GraphSensorElement[] = [];

        var meteoData: Record<string, any>[] = [];
        let weatherInverterId: string | undefined = undefined;
        const sensorsById = _.keyBy(sensors, "id");
        for (const timestamp of _.sortBy(Object.keys(data).map(Number))) {
          // Avoid adding weather sensors in case of multisite view
          if (!weatherInverterId)
            weatherInverterId = _.findKey(
              data[timestamp],
              (inverterData) => inverterData.inverter_sn == "_weather"
            );
          if (weatherInverterId) {
            const weatherValues = data[timestamp]?.[weatherInverterId]?.values;
            if (weatherValues) {
              const weatherData = extractWeatherData(
                weatherValues,
                sensorsById
              );
              meteoData.push({
                x: format(timestamp, "HH:mm"),
                img: weatherData.iconNum,
                temp: weatherData.temp,
              });
            }
          }
        }

        const filteredSensors = viewSensorsId
          .map((sensorId) => sensorsById[sensorId])
          .filter(
            (sensor) => !sensor.disabled && !sensor.inverter_sn!.startsWith("_")
          );

        var palette = distinctColors({
          count: filteredSensors.length,
          lightMax: 80,
        });

        filteredSensors.map((sensorToDisplay, index) => {
          var sensorData: Record<string, any>[] = [];
          for (const timestamp of _.sortBy(Object.keys(data).map(Number))) {
            var value = sensorToDisplay.id
              ? data[timestamp]?.[sensorToDisplay.inverter_id]?.values[
                  sensorToDisplay.id
                ]
              : null;
            var newSensorData: Record<string, any> = {
              x: format(timestamp, "HH:mm"),
            };

            newSensorData[String(sensorToDisplay.id!)] = isRelativeValues
              ? Math.round(Number(value) * 100)
              : Math.round(Number(value) * 100) / 100;

            sensorData.push(newSensorData);
          }

          if (newData.length === 0) {
            newData = sensorData;
          } else {
            newData = _(newData)
              .keyBy("x")
              .merge(_.keyBy(sensorData, "x"))
              .values()
              .value();
          }

          var selected = sensorToDisplay.id === sensor.id;
          newSensorList.push({
            sensor: sensorToDisplay,
            color: palette[index].hex(),
            selected: selected,
          });
        });

        // Ajout de la météo
        newData = _(newData)
          .keyBy("x")
          .merge(_.keyBy(meteoData, "x"))
          .values()
          .value();

        if (!keepSensorsList) {
          setSensorsList(newSensorList);
        }

        setData(newData);
      }
    });
  };

  useInterval(getData, 120000);

  useEffect(() => {
    // Récupère données du backend pour former la liste valuesList (données, state, color)
    // Se relance dès que la date change
    getData(!(sensorsList.length === 0));
  }, [date]);

  useEffect(() => {
    getData();
  }, [isRelativeValues]);

  const handleClickSelect = (index: number) => {
    var newSensorsList = [...sensorsList];
    newSensorsList[index].selected = !sensorsList[index].selected;
    setSensorsList(newSensorsList);
  };

  const getControls = () => (
    <GraphDialogControls
      autoScale={autoScale}
      setAutoScale={setAutoScale}
      relativeValues={isRelativeValues}
      setRelativeValues={setRelativeValues}
      date={date}
      setDate={setDate}
      handleClose={handleClose}
      toggleLegendOpen={() => setLegendClose(!legendIsClose)}
    />
  );

  const getContent = () => {
    if (!forceShowInPortrait && isPortrait() && isMobile()) {
      return (
        <PleaseRotatePhone
          handleCancel={handleClose}
          handleOverride={() => {
            setForceShowInPortrait(true);
            setLegendClose(true);
          }}
        />
      );
    } else {
      return (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
        >
          {isMobileSafari && getControls()}
          <Box
            display="flex"
            flexDirection="row"
            maxHeight="100%"
            minHeight={0}
            maxWidth="100%"
            height="100%"
            style={{ backgroundColor: AppColor.greyBackground }}
          >
            <div
              style={{
                flex: 1,
                width: 0,
                margin: 10,
                minHeight: "100%",
              }}
            >
              {/* https://github.com/recharts/recharts/issues/172#issuecomment-240036906 */}
              <Graph
                data={data}
                sensorsList={sensorsList}
                relativeValues={isRelativeValues}
                unit={sensor.unit ?? ""}
                viewName={view.name}
                aspect={dimensions.width / dimensions.height}
                autoScale={autoScale}
              />
            </div>
            <Box>
              {!legendIsClose && (
                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 0,
                    backgroundColor: AppColor.white,
                    width: 200,
                    height: "100%",
                  }}
                >
                  <Box
                    mt={2}
                    mb={1}
                    mx={4}
                    style={{ textAlign: "center", fontSize: 18 }}
                  >
                    {view.name.toUpperCase()}
                  </Box>
                  <Box
                    style={{
                      overflow: "auto",
                    }}
                  >
                    <List>
                      {sensorsList.map((element, index) => (
                        <ListItem
                          button
                          onClick={() => handleClickSelect(index)}
                        >
                          <Box
                            style={{
                              color: element.selected ? element.color : "grey",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            {element.sensor.label ??
                              `SN ${element.sensor.inverter_sn}, Capteur ${element.sensor.sensor_index}`}
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Paper>
              )}
            </Box>
          </Box>
          {!isMobileSafari && getControls()}
        </Box>
      );
    }
  };

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={handleClose}
      classes={isMobile() ? undefined : { paper: classes.dialogPaper }}
    >
      {getContent()}
    </Dialog>
  );
};
