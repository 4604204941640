import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import {
  AddCircle,
  DeleteOutlined,
  ErrorOutlined,
  NotificationsOff,
} from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import { ackAlarm, deleteAlarm } from "../../../repository/network";
import { minutesFromStartOfDayToHHMM } from "../../../utils/util";
import { CreateAlarmDialog } from "../CreateAlarmDialog";
import { StringTooltipParams } from "./StringTooltip";

const MAX_EVENTS_TO_DISPLAY_PER_ALARM = 10;
const MAX_LOGS_TO_DISPLAY_PER_EVENT = 10;

export function TooltipAlarmTab(props: StringTooltipParams) {
  const {
    sensor,
    setSensor,
    tooltipStringInfo,
    view,
    userData,
    updateUserData,
  } = props;

  async function removeAlarm(alarmId: number) {
    if (window.confirm("Supprimer cette alarme ?")) {
      const { status } = await deleteAlarm({ alarmId });
      if (status === 200) {
        setSensor(
          {
            ...sensor,
            alarmDefinitions: (sensor.alarmDefinitions ?? []).filter(
              (alarm) => alarm.id !== alarmId
            ),
          },
          false
        );
      } else {
        alert(
          "Impossible de supprimer cette alarme, veuillez contacter le support"
        );
      }
    }
  }

  const [createAlarmOpen, setCreateAlarmOpen] = React.useState(false);

  return (
    <>
      <Button
        color="primary"
        startIcon={<AddCircle />}
        onClick={() => setCreateAlarmOpen(true)}
        fullWidth
      >
        Nouvelle alarme
      </Button>

      <List dense={true}>
        {(sensor.alarmDefinitions ?? []).map((alarm, i) => {
          const operations: Record<string, string> = {
            "<": "<",
            "<=": "≤",
            "=": "=",
            ">=": "≥",
            ">": ">",
            "!=": "≠",
          };
          const primary =
            alarm.type !== "value" ||
            alarm.operation === null ||
            alarm.threshold === null
              ? "Alarme inconnue"
              : `𝒙 ${operations[alarm.operation]} ${alarm.threshold} ${
                  sensor.unit ?? ""
                }`;

          const secondary = [];
          if (alarm.watch_from !== null && alarm.watch_to !== null) {
            secondary.push(
              `${minutesFromStartOfDayToHHMM(
                alarm.watch_from
              )}-${minutesFromStartOfDayToHHMM(alarm.watch_to)}`
            );
          }

          if (
            alarm.contiguous_matches_before_trigger !== null &&
            alarm.contiguous_matches_before_trigger !== 1
          ) {
            secondary.push(
              `${alarm.contiguous_matches_before_trigger}× de suite`
            );
          }

          return (
            <ListItem
              key={alarm.id}
              style={{
                margin: 0,
                padding: 5,
                marginBottom: 10,
                background:
                  alarm.unacknowledgedEvents.length !== 0 ? red[50] : blue[50],
                borderTop:
                  "2px solid " +
                  (alarm.unacknowledgedEvents.length !== 0
                    ? red[100]
                    : blue[100]),
              }}
            >
              <ListItemText
                primary={
                  <Box display="flex" flexDirection="row">
                    <Box flexGrow={10}>
                      <span style={{ fontWeight: "bold" }}>{primary}</span>

                      {secondary.length !== 0 && (
                        <Box style={{ color: "#666" }}>
                          {secondary.join(", ")}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => removeAlarm(alarm.id)}
                      >
                        <DeleteOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                }
                secondary={
                  <>
                    {alarm.unacknowledgedEvents
                      .slice(0, MAX_EVENTS_TO_DISPLAY_PER_ALARM)
                      .map((event) => (
                        <Box mt={1} display="flex" flexDirection="row">
                          <Box mr={1}>
                            <ErrorOutlined
                              fontSize="small"
                              style={{ color: red[500] }}
                            />
                          </Box>
                          <Box flexGrow={1}>
                            {event.metadata && (
                              <ul
                                style={{
                                  listStyle: "none",
                                  padding: 0,
                                  borderBottom: "1px solid #ccc",
                                }}
                              >
                                {(event.metadata.faultySequence ?? [])
                                  .slice(0, MAX_LOGS_TO_DISPLAY_PER_EVENT)
                                  .map((faultyValue) => (
                                    <li>
                                      {format(
                                        new Date(faultyValue.timestamp),
                                        "dd/MM à HH:mm"
                                      )}{" "}
                                      : {faultyValue.value ?? "null"}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </Box>
                        </Box>
                      ))}
                    {alarm.unacknowledgedEvents.length !== 0 && (
                      <Button
                        startIcon={<NotificationsOff />}
                        size="small"
                        color="primary"
                        onClick={() => {
                          ackAlarm(alarm.id)
                            .then(() => {
                              setSensor(
                                {
                                  ...sensor,
                                  alarmDefinitions: (
                                    sensor.alarmDefinitions ?? []
                                  ).map((alarmDefinition) => ({
                                    ...alarmDefinition,
                                    ...(alarmDefinition.id === alarm.id && {
                                      unacknowledgedEvents: [],
                                    }),
                                  })),
                                },
                                false
                              );
                            })
                            .catch((e) => {
                              alert(
                                "Erreur, merci de rééssayer ou contactez le support"
                              );
                            });
                        }}
                        fullWidth
                      >
                        Acquitter
                      </Button>
                    )}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>

      <CreateAlarmDialog
        open={createAlarmOpen}
        handleClose={() => setCreateAlarmOpen(false)}
        sensor={sensor}
        setSensor={setSensor}
        tooltipStringInfo={tooltipStringInfo}
        view={view}
        userData={userData}
        updateUserData={updateUserData}
      />
    </>
  );
}
