import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useState } from "react";
import { UserType } from "../../model/types";
import { isPhoneNumberValid } from "../../utils/util";

export default function AcceptSmsDialog({
  open,
  handleClose,
  userData,
  updateUserData,
  setSupportNotificationSms,
}: {
  open: boolean;
  handleClose: () => void;
  userData: UserType;
  updateUserData: (data: UserType) => Promise<void>;
  setSupportNotificationSms: (data: boolean) => void;
}) {
  const [acceptSms, setAcceptSms] = useState(userData.acceptSms);
  const [phoneNumber, setPhoneNumber] = useState(
    userData.phoneNumber?.replace("+33", "")
  );

  const onSubmit = async () => {
    if (acceptSms && isPhoneNumberValid(phoneNumber)) {
      await updateUserData({
        ...userData,
        acceptSms: acceptSms,
        phoneNumber: "+33" + phoneNumber,
      });
      handleClose();
      setSupportNotificationSms(true);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Activation des alertes SMS
      </DialogTitle>
      <DialogContent>
        <Alert
          severity={acceptSms ? "success" : "warning"}
          style={{ marginBottom: "10px" }}
        >
          <AlertTitle>Condition d'utilisation :</AlertTitle>

          <p>En continuant, j’autorise Dataview à m’envoyer des SMS.</p>
          <p>
            Pour supprimer une alarme, accédez à l’onglet “Alarmes” en cliquant
            sur un capteur puis cliquez sur la poubelle.
          </p>
          <p>
            Vous pouvez désactiver l’envoi de tous les SMS dans les paramètres
            en haut à gauche.
          </p>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptSms}
                onChange={(e) => {
                  setAcceptSms(e.target.checked);
                }}
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
              />
            }
            label="J'accepte"
          />
        </Alert>
        <Divider />
        <Alert
          severity={isPhoneNumberValid(phoneNumber) ? "success" : "warning"}
          style={{ marginTop: "10px" }}
        >
          <AlertTitle>Numéro de téléphone</AlertTitle>
          <TextField
            variant={"outlined"}
            id="standard-start-adornment"
            value={phoneNumber}
            helperText={"Ex:+33 659353436"}
            margin={"normal"}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={!isPhoneNumberValid}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+33</InputAdornment>
              ),
            }}
          />
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={onSubmit} color="primary">
          Envoyez
        </Button>
      </DialogActions>
    </Dialog>
  );
}
