import { Box, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GraphSensorElement } from "../../../../model/types";

interface GraphProps {
  data: Record<string, any>[];
  sensorsList: GraphSensorElement[];
  relativeValues: boolean;
  unit: string;
  viewName: string;
  aspect: number;
  autoScale: boolean;
}

export const Graph = ({
  data,
  sensorsList,
  relativeValues,
  unit,
  viewName,
  aspect,
  autoScale,
}: GraphProps) => {
  const [maxLengthLabel, setMaxLengthLabel] = useState(0);

  const values: number[] = [];
  let dataMin: number = 0;
  let dataMax: number = 0;
  let dataDiffMinMax: number = 0;

  const [units, setUnits] = useState<string[]>([]);
  const [unitListSelectedSensor, setUnitListSelectedSensor] = useState<
    string[]
  >([]);

  const [minAutoScale, setMinAutoScale] = useState(0);
  const [maxAutoScale, setMaxAutoScale] = useState(0);

  useEffect(() => {
    const unitList = sensorsList.map((sensor) => sensor.sensor.unit ?? "");

    const unitsFormated = unitList.map((name) => name.toUpperCase().trim());
    setUnits([...Array.from(new Set(unitsFormated))]);

    let tempUnitListSelectedSensor: string[] = [];
    sensorsList
      .filter((sensor) => sensor.selected === true)
      .map((sensorsSelected) => {
        tempUnitListSelectedSensor.push(sensorsSelected.sensor.unit ?? "");
        let tempMaxLength = maxLengthLabel;
        data.map((dataLine) => {
          let value = dataLine[sensorsSelected.sensor.id!];
          let floorValue = Math.floor(dataLine[sensorsSelected.sensor.id!]);

          if (!Number.isNaN(Number(value))) values.push(value);

          if (String(floorValue).length > tempMaxLength) {
            tempMaxLength = String(floorValue).length;
          }
        });
        setMaxLengthLabel(tempMaxLength);
      });
    dataMin = Math.min(...values);
    dataMax = Math.max(...values);
    dataDiffMinMax = Math.abs(dataMin - dataMax);

    setMinAutoScale(dataMin === 0 ? dataMin : dataMin - dataDiffMinMax * 0.05);
    setMaxAutoScale(dataMax + dataDiffMinMax * 0.05);
    setUnitListSelectedSensor(tempUnitListSelectedSensor);
  }, [sensorsList, data]);

  const renderTooltip = (props: any) => {
    const { payload } = props;

    return (
      <Paper elevation={3} style={{ padding: 15 }}>
        {payload?.length > 0 ? (
          <>
            <Box mb={1}>
              {viewName} - {payload[0]?.payload["x"]}
            </Box>
            <Box mb={2}>
              {payload.map((data: any, index: number) => (
                <Box
                  key={index}
                  style={{ color: data.color, fontSize: 14, marginBottom: 1 }}
                >
                  {data.name} :{" "}
                  {Number.isNaN(Number(data.value))
                    ? "-"
                    : relativeValues
                    ? data.value + " %"
                    : data.value + " " + unitListSelectedSensor[index]}
                </Box>
              ))}
            </Box>
            {payload[0]?.payload["img"] ? (
              <Box display="flex" flexDirection="row" alignItems="center">
                <img
                  src={`/weather/${payload[0]?.payload["img"]}.png`}
                  width="30"
                  height="30"
                />
                <Box ml={1} style={{ fontSize: 14 }}>
                  {payload[0]?.payload["temp"]} °C
                </Box>
              </Box>
            ) : null}
          </>
        ) : null}
      </Paper>
    );
  };

  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" tick={{ fontSize: 14 }} />
        <YAxis
          tick={{ fontSize: 14 }}
          width={maxLengthLabel > 5 ? maxLengthLabel * 10 : undefined}
          tickFormatter={(x) => String(Math.floor(x))}
          domain={
            relativeValues
              ? [0, 100]
              : autoScale
              ? [minAutoScale, maxAutoScale]
              : [0, maxAutoScale]
          }
          label={{
            value: relativeValues ? "%" : units.length > 1 ? "" : unit,
            angle: -90,
            position: "insideRight",
            offset: 40,
          }}
        />
        <Tooltip content={renderTooltip} isAnimationActive={false} />

        {sensorsList
          .filter((sensor) => sensor.selected === true)
          .map((sensorToDraw, index) => {
            return (
              <Line
                key={index}
                isAnimationActive={false}
                dot={false}
                type="monotone"
                dataKey={sensorToDraw.sensor.id!}
                name={
                  sensorToDraw.sensor.label ??
                  `SN ${sensorToDraw.sensor.inverter_id}, Capteur ${sensorToDraw.sensor.sensor_index}`
                }
                stroke={sensorToDraw.color}
                activeDot={{ r: 8 }}
              />
            );
          })}
      </LineChart>
    </ResponsiveContainer>
  );
};
