import { LinearProgress, Paper, Snackbar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { addDays } from "date-fns";
import _ from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppColor from "../assets/Color";
import { useInterval, useLocalStorage } from "../hooks/hooks";
import {
  Sensor,
  ServerReturnedLogs,
  Site,
  TimestampAtStart,
  UserType,
  View,
  WeatherData,
} from "../model/types";
import {
  fetchDay,
  fetchLatestTimestamp,
  fetchSensors,
  fetchSites,
  fetchViews,
  getSensorIds,
  userGetData,
  userSetData,
} from "../repository/network";
import {
  extractWeatherData,
  getDayBounds,
  isMultisiteFeatureFlagEnabled,
  LocalStorageSettings,
} from "../utils/util";
import "./App.css";
import { GlobalSettings } from "./component/GlobalSettings";
import { SiteViewSettings } from "./component/SiteViewSettings";
import TimeSlider from "./component/TimeSlider";
import { TopAppBar } from "./component/TopAppBar";
import UserSettings from "./component/userSettings/UserSettings";
import { InviteUserView } from "./view/InviteUserView";
import { Login } from "./view/Login";
import { ResetPasswordView } from "./view/ResetPasswordView";
import { SingleSiteView } from "./view/SingleSiteView";

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Helvetica", "Arial", sans-serif`,
  },
  palette: {
    primary: {
      main: AppColor.blueDataview,
    },
    secondary: {
      main: AppColor.greyDataview,
    },
  },
});

function Viewer() {
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState<null | ServerReturnedLogs>(null);
  const [
    localStorageSettings,
    setLocalStorageSettings,
  ] = useLocalStorage<LocalStorageSettings>("settings", {
    displayTimeBounds: [0, 60 * 24 - 1],
    multiSiteView: false,
    relativeValues: false,
  });

  const [displayDisabledSensors, setDisplayDisabledSensors] = useLocalStorage(
    "displayDisabledSensors",
    false
  );
  const [timestamp, setTimestamp] = useState(0);
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState(true);
  const [firstDataLoaded, setFirstDataLoaded] = useState(false);

  const [latestTimestamp, setLatestTimestamp] = useState(0);
  const [deltaDay, setDeltaDay] = useState(0);
  const [
    shouldSetTimestampAtStart,
    setShouldSetTimestampAtStart,
  ] = useState<TimestampAtStart>("end");
  const [realTime, setRealTime] = useState(true);
  const [
    openSettingsPopupAnchorEl,
    setOpenSettingsPopupAnchorEl,
  ] = useState<Element | null>(null); // null = settings fermés
  const [
    openUserPopupAnchorEl,
    setOpenUserPopupAnchorEl,
  ] = useState<Element | null>(null); // null = settings fermés
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [bypassCoefficients, setBypassCoefficients] = useState(false);
  const [userData, setUserData] = useState<UserType>();
  const [views, setViews] = useState<View[]>([]);
  const [viewId, setViewId] = useLocalStorage<number | null>("viewId", null);
  const [site, setSite] = useState<Site | null>(null);
  const view = views.find((view) => view.id == viewId);
  const now = new Date();
  const [lower, upper] = getDayBounds(
    addDays(now, deltaDay),
    localStorageSettings.displayTimeBounds[0],
    localStorageSettings.displayTimeBounds[1]
  );
  const filteredData =
    data == null
      ? null
      : _.pickBy(data, (val, key) => {
          const timestamp = Number(key);
          return timestamp >= lower.getTime() && timestamp <= upper.getTime();
        });

  const dataTimestamps = _.isEqual(filteredData, {})
    ? null
    : filteredData
    ? Object.keys(filteredData)
        .map((v) => +v)
        .sort()
    : [];

  const getDay = (delta: number, relative: boolean) =>
    fetchDay(delta, viewId, relative).then(setData);

  const getLatestTimestamp = () => {
    fetchLatestTimestamp().then((d) => {
      if (d.latestTimestamp !== latestTimestamp) {
        setLatestTimestamp(d.latestTimestamp);
      }
    });
  };

  const showError = useCallback((message: string) => {
    setErrorMessage(message);
    setErrorOpen(true);
  }, []);

  useInterval(getLatestTimestamp, 10000);

  useEffect(() => {
    fetchSites().then(setSites);
    fetchSensors().then(setSensors);
    fetchViews().then(setViews);
  }, []);
  useEffect(() => {
    setLoading(true);
    getDay(deltaDay, localStorageSettings.relativeValues);
  }, [deltaDay, latestTimestamp, localStorageSettings.relativeValues, viewId]);

  useEffect(() => {
    setLoading(false);
    if (dataTimestamps === null) {
      return;
    }
    if (shouldSetTimestampAtStart === "mid") {
      // exception pour que le curseur aille au milieu au changement de jour
      console.log("mid");
      return;
    }
    if (!realTime) {
      console.log("blocked");
      return;
    }
    if (shouldSetTimestampAtStart === "start") {
      setTimestamp(dataTimestamps[0]);
    }
    if (shouldSetTimestampAtStart === "end") {
      setTimestamp(dataTimestamps[dataTimestamps.length - 1]);
    }
  }, [data]);

  useEffect(() => {
    if (realTime) {
      if (deltaDay !== 0) {
        setShouldSetTimestampAtStart("end");
        setDeltaDay(0);
      } else if (dataTimestamps !== null) {
        setTimestamp(dataTimestamps[dataTimestamps.length - 1]);
      }
    }
  }, [realTime]);

  useEffect(() => {
    if (dataTimestamps !== null && dataTimestamps.length !== 0) {
      if (shouldSetTimestampAtStart === "mid") {
        setTimestamp(dataTimestamps[Math.floor(dataTimestamps.length / 2)]);
        setShouldSetTimestampAtStart("end");
        return;
      }

      if (dataTimestamps.includes(timestamp)) {
      } else if (realTime || timestamp > Math.max(...dataTimestamps)) {
        console.log(
          "Timestamp déborde (trop grand), on le met à la plus grande valeur dispo"
        );
        setTimestamp(Math.max(...dataTimestamps));
      } else if (timestamp < Math.min(...dataTimestamps)) {
        console.log(
          "Timestamp déborde (trop petit), on le met à la plus petite valeur dispo"
        );
        setTimestamp(Math.min(...dataTimestamps));
      }

      if (!firstDataLoaded) {
        setFirstDataLoaded(true);
      }
    }
  }, [dataTimestamps]);

  useEffect(() => {
    userGetData()
      .then(setUserData)
      .catch(() => alert("Impossible de récupérer les données du compte"));
  }, []);

  const updateUserData = async (userData: UserType) => {
    try {
      const res = await userSetData(userData);
      if (res.status !== 200) {
        throw res;
      }
      setUserData(userData);
    } catch (e) {
      throw e;
    }
  };

  const onSliderChange = (_: any, value: string) => {
    setRealTime(
      deltaDay === 0 &&
        dataTimestamps !== null &&
        Number(value) === dataTimestamps[dataTimestamps.length - 1]
    );
    setTimestamp(Number(value));
  };

  const onDayOverflow = async (delta: 1 | -1) => {
    if (loading) return;
    setLoading(true);
    const valid = deltaDay + delta <= 0;
    if (valid) {
      setShouldSetTimestampAtStart(delta === 1 ? "start" : "end");
      setDeltaDay(deltaDay + delta);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (viewId == null && views[0]) setViewId(views[0].id);
  }, [views]);

  useEffect(() => {
    const siteId = view?.site_id ?? null;
    setSite(sites.find((site) => site.id == siteId) ?? null);
  }, [viewId, views, sites]);

  let weatherData: WeatherData | undefined = undefined;

  if (viewId) {
    // Results can be impredictable in case of multiple weather inverters
    const weatherInverterId = _.findKey(
      filteredData?.[timestamp],
      (inverterData) => inverterData.inverter_sn == "_weather"
    );
    if (weatherInverterId) {
      const rawWeatherData =
        filteredData?.[timestamp]?.[weatherInverterId]?.values;
      if (rawWeatherData) {
        weatherData = extractWeatherData(
          rawWeatherData,
          _.keyBy(sensors, "id")
        );
      }
    }
  }
  const [readState, setReadState] = useState<[]>([]);

  useEffect(() => {
    getSensorIds().then((data) => setReadState(data));
  }, []);

  useInterval(() => {
    getSensorIds().then((data) => setReadState(data));
  }, 5000);

  return (
    <Fragment>
      <Box height="100%" display="flex" flexDirection="column">
        <TopAppBar
          onOpenUserClick={setOpenUserPopupAnchorEl}
          onOpenSettingsClick={setOpenSettingsPopupAnchorEl}
          relativeValues={localStorageSettings.relativeValues}
          setRelativeValues={(relativeValues: boolean) =>
            setLocalStorageSettings({
              ...localStorageSettings,
              relativeValues,
            })
          }
        ></TopAppBar>

        {userData !== undefined && (
          <>
            <GlobalSettings
              anchorEl={openSettingsPopupAnchorEl}
              onCloseRequest={() => setOpenSettingsPopupAnchorEl(null)}
              localStorageSettings={localStorageSettings}
              setLocalStorageSettings={setLocalStorageSettings}
              displayDisabledSensors={displayDisabledSensors}
              setDisplayDisabledSensors={setDisplayDisabledSensors}
              bypassCoefficients={bypassCoefficients}
              setBypassCoefficients={setBypassCoefficients}
            />

            <UserSettings
              anchorEl={openUserPopupAnchorEl}
              onCloseRequest={() => setOpenUserPopupAnchorEl(null)}
              userData={userData}
              updateUserData={updateUserData}
            />
          </>
        )}

        <Box mt={3} mb={1}>
          {data && views && views.length ? (
            <SiteViewSettings
              loading={loading}
              filteredData={filteredData}
              views={views}
              viewId={viewId}
              setViewId={setViewId}
              multiSiteView={localStorageSettings.multiSiteView}
              dataTimestamps={dataTimestamps}
              realTime={realTime}
              relativeValues={localStorageSettings.relativeValues}
              setRelativeValues={(relativeValues: boolean) =>
                setLocalStorageSettings({
                  ...localStorageSettings,
                  relativeValues,
                })
              }
              deltaDay={deltaDay}
              setRealTime={setRealTime}
              setLoading={setLoading}
              setDeltaDay={setDeltaDay}
              setShouldSetTimestampAtStart={setShouldSetTimestampAtStart}
            />
          ) : null}
        </Box>

        <Box overflow="scroll" flexGrow={1} display="flex">
          {data && sites && sites.length && userData !== undefined ? (
            localStorageSettings.multiSiteView &&
            isMultisiteFeatureFlagEnabled() ? (
              <></>
            ) : (
              view && (
                <SingleSiteView
                  view={view}
                  site={site}
                  firstDataLoaded={firstDataLoaded}
                  sensors={sensors}
                  setSensors={setSensors}
                  showError={(text) => showError(text)}
                  timestamp={timestamp}
                  relativeValues={localStorageSettings.relativeValues}
                  filteredData={filteredData}
                  loading={loading}
                  displayDisabledSensors={displayDisabledSensors}
                  bypassCoefficients={bypassCoefficients}
                  goToTimestamp={(timestamp: number) => {
                    if (timestamp !== undefined) {
                      setTimestamp(timestamp);
                      setRealTime(false);
                    }
                  }}
                  userData={userData}
                  updateUserData={updateUserData}
                  readState={readState}
                />
              )
            )
          ) : (
            <Paper style={{ padding: 10, margin: 10 }}>
              <LinearProgress variant="query" />
            </Paper>
          )}
        </Box>

        <Box>
          <Paper
            style={{
              padding: "2px 20px",
              margin: 2,
              paddingBottom: 30,
            }}
          >
            <TimeSlider
              value={timestamp || 0}
              data={dataTimestamps}
              onDayOverflow={onDayOverflow}
              onChange={onSliderChange}
              weatherData={weatherData}
            ></TimeSlider>
          </Paper>
        </Box>
        <Snackbar open={errorOpen} autoHideDuration={2000}>
          <Alert
            onClose={() => setErrorOpen(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Fragment>
  );
}

const App: React.FC = () => {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              <Login></Login>
            </Route>
            <Route path="/invite">
              <InviteUserView></InviteUserView>
            </Route>
            <Route path="/resetPassword">
              <ResetPasswordView></ResetPasswordView>
            </Route>
            <Route path="/">
              <Viewer></Viewer>
            </Route>
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
