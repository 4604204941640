import { Popover } from "@material-ui/core";
import React, { useState } from "react";
import { UserType } from "../../../model/types";
import { resetPassword } from "../../../repository/network";
import UserSettingsView from "./UserSettingsView";

type UserSettingsProps = {
  anchorEl: Element | null;
  onCloseRequest: () => void;
  userData: UserType;
  updateUserData: (value: UserType) => Promise<void>;
};

export default function UserSettings({
  userData,
  updateUserData,
  anchorEl,
  onCloseRequest,
}: UserSettingsProps) {
  const open = Boolean(anchorEl);

  const [loading, setLoading] = useState(false);

  const userUpdateHandler = (updatedUser: UserType) => {
    (async () => {
      try {
        setLoading(true);
        await updateUserData({
          ...userData,
          ...updatedUser,
        });
      } catch (e) {
        alert("❌ Impossible de mettre à jour les préférences");
      } finally {
        setLoading(false);
      }
    })();
  };

  const passwordResetHandler = () => {
    (async () => {
      try {
        setLoading(true);
        const res = await resetPassword({ email: userData.email });
        if (res.status === 200) {
          alert(`✅ Un lien vous a été envoyé par mail à ${userData.email}.`);
          return;
        } else if (res.status === 429) {
          alert(
            "❌ Vous avez demandé un changement de mot de passe trop récemment."
          );
        } else {
          alert("❌ Opération impossible, veuillez contacter le support");
          return;
        }
      } catch (e) {
        alert("❌ Opération impossible, veuillez contacter le support");
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onCloseRequest}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <UserSettingsView
        userData={userData}
        loading={loading}
        requestUserUpdate={userUpdateHandler}
        requestPasswordReset={passwordResetHandler}
      />
    </Popover>
  );
}
