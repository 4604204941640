import { AppBar, IconButton, Switch, Toolbar } from "@material-ui/core";
import { AccountCircle, Settings } from "@material-ui/icons";
import React from "react";
import AppColor from "../../assets/Color";
import { isMobile } from "../../utils/util";

interface TopAppBarProps {
  onOpenSettingsClick: (event: Element) => void;
  onOpenUserClick: (event: Element) => void;
  relativeValues: boolean;
  setRelativeValues: (v: boolean) => void;
}

export const TopAppBar = (props: TopAppBarProps) => (
  <AppBar position="relative" style={{ background: "#ffffff" }}>
    <Toolbar variant={isMobile() ? "dense" : "regular"}>
      <IconButton onClick={(e) => props.onOpenSettingsClick(e.currentTarget)}>
        <Settings style={{ color: AppColor.greyIcon }} />
      </IconButton>
      <IconButton onClick={(e) => props.onOpenUserClick(e.currentTarget)}>
        <AccountCircle style={{ color: AppColor.greyIcon }} />
      </IconButton>
      <img
        alt="logo"
        height={isMobile() ? "35" : "50"}
        style={{ margin: "0 auto" }}
        src="/logo-web.png"
      />
      {isMobile() ? (
        <>
          <b style={{ color: AppColor.textColor }}>𝒙</b>
          <Switch
            checked={props.relativeValues}
            onChange={(e) => props.setRelativeValues(e.currentTarget.checked)}
            color="primary"
          />
          <b style={{ color: AppColor.textColor }}>%</b>
        </>
      ) : (
        <></>
      )}
    </Toolbar>
  </AppBar>
);
