import {
  Card,
  TextField,
  Typography,
  Box,
  Snackbar,
  Button,
  Link,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ArrowForward } from "@material-ui/icons";
import React, { FormEvent, useEffect } from "react";
import AppColor from "../../assets/Color";
import { login } from "../../repository/network";
import { DomEvent } from "leaflet";
import ResetPasswordDialog from "../component/ResetPasswordDialog";
import { PasswordField } from "material-ui-password";

export const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [resetPasswordOpen, setResetPasswordOpen] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const accountCreated = Boolean(urlParams.get("accountCreated"));
  const updatedPassword = Boolean(urlParams.get("updatedPassword"));
  const emailSent = Boolean(urlParams.get("emailSent"));
  const mustLogin = Boolean(urlParams.get("mustLogin"));

  useEffect(() => {
    if (mustLogin) {
      setErrorMessage("Veuillez vous reconnecter");
    }
  }, [mustLogin]);

  useEffect(() => {
    if (!mustLogin && localStorage.getItem("authorization")) {
      window.location.replace("/");
    }
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await login({ email, password });
      if (res === false) {
        setErrorMessage("Identifiants invalides.");
        return;
      }
      localStorage.setItem("authorization", res.userId + "," + res.token);
      window.location.href = "/";
    } catch (e) {
      setErrorMessage(
        "Erreur serveur, merci de rééssayer plus tard ou de nous contacter"
      );
    }
  };
  return (
    <Box
      className="login-bg"
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Card
        style={{
          padding: 30,
          margin: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/logo-web-transparent.png"
          style={{ maxWidth: "70%", marginBottom: 20 }}
        />

        <Typography
          style={{
            fontWeight: "bold",
            borderBottom: "3px solid " + AppColor.blueDataview,
            width: "auto",
            margin: "0 auto",
          }}
          variant="h5"
          component="h5"
          gutterBottom
        >
          Connexion
        </Typography>

        <Typography variant="body1" style={{ marginTop: 30 }}>
          Si vous n'avez pas encore de compte client, écrivez-nous à{" "}
          <Link href="mailto:contact@dataview.fr">contact@dataview.fr</Link>
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            autoFocus
            autoComplete="on"
            type="email"
            id="email"
            label="Email"
            variant="outlined"
            style={{ marginTop: 40 }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            required
          />
          <PasswordField
            id="password"
            label="Mot de passe"
            variant="outlined"
            style={{ marginTop: 15, marginBottom: 10 }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
          >
            Connexion
            <ArrowForward />
          </Button>
        </form>
        <Link
          component="button"
          variant="body2"
          style={{ marginTop: 15, marginBottom: 10 }}
          onClick={() => setResetPasswordOpen(true)}
        >
          Mot de passe oublié ?
        </Link>
      </Card>

      <ResetPasswordDialog
        open={resetPasswordOpen}
        currentEmail={email}
        handleClose={() => setResetPasswordOpen(false)}
      />

      <Snackbar
        open={accountCreated}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Votre compte est créé !</Alert>
      </Snackbar>

      <Snackbar
        open={updatedPassword}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          Votre mot de passe a bien été modifié !
        </Alert>
      </Snackbar>

      <Snackbar
        open={emailSent}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">
          Vous allez recevoir un e-mail pour votre mot de passe
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorMessage !== null}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setErrorMessage(null)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
