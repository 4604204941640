import { Parser } from "expr-eval";

const parser = new Parser({
  operators: {
    add: true,
    divide: true,
    factorial: true,
    multiply: true,
    power: true,
    subtract: true,
    conditional: false,
    remainder: false,
    logical: false,
    comparison: false,
    in: false,
    assignment: false,
  },
});

export const applyCoefficients = (
  values: (number | null)[],
  coefficients: (string | null)[] | null
) => {
  if (coefficients === null) {
    return values;
  }
  return values.map((value, i) => applyCoefficient(value, coefficients[i]));
};

export const applyCoefficient = (
  value: number | null,
  coefficient: string | null | undefined
) => {
  if (value === null) {
    return value;
  }
  if (coefficient === undefined || coefficient === null) {
    return value;
  }
  try {
    const res = parser.evaluate(coefficient, { x: value });
    if (typeof res !== "number") {
      return NaN;
    }
    return res;
  } catch (e) {
    console.error(
      `Error while applying coefficient "${coefficient}" : ${e.message}`
    );
    return NaN;
  }
};
