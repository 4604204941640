import { Box, Button } from "@material-ui/core";
import { MobileScreenShare } from "@material-ui/icons";
import React from "react";

interface PleaseRotatePhoneProps {
  handleCancel: () => void;
  handleOverride: () => void;
}

export const PleaseRotatePhone = ({
  handleCancel,
  handleOverride,
}: PleaseRotatePhoneProps) => {
  return (
    <>
      <Box
        margin={2}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <MobileScreenShare style={{ fontSize: 45 }} />
        <Box my={4}>Tournez votre téléphone</Box>
        <Button variant="outlined" color="primary" onClick={handleCancel}>
          Retour
        </Button>
      </Box>
      <Box mb={2}>
        <Button
          size="small"
          color="secondary"
          fullWidth
          onClick={handleOverride}
        >
          Afficher en portrait
        </Button>
      </Box>
    </>
  );
};
