import { Container, Typography } from "@material-ui/core";
import { format } from "date-fns";
import React, { FunctionComponent } from "react";

interface WaitingForLogsProps {
  timestamp: number;
  latestTimestampForView: number;
  onClick: () => void;
}

export const WaitingForLogs: FunctionComponent<WaitingForLogsProps> = ({
  timestamp,
  latestTimestampForView,
  onClick,
}) => (
  <Container>
    <span style={{ fontSize: "10em" }}>☕</span>
    <br />
    <Typography variant="h6" gutterBottom>
      Dataview attend le prochain log...
    </Typography>
    <Typography>
      Les valeurs de {format(timestamp, "HH:mm")} s'afficheront automatiquement
      dès réception du fichier. Dernière valeur reçue à&nbsp;
      <a href="#" onClick={onClick}>
        {format(latestTimestampForView, "HH:mm")}
      </a>
    </Typography>
  </Container>
);
