const AppColor = {
  textColor: "#444444",
  textColorGrey: "#ACACAC",
  blueDataview: "#3784b2",
  greyDataview: "#6a6b6d",

  greyBackground: "#F9FAFD",
  greyIcon: "#444444",
  lightGrey: "#F0F2F5",
  noteImportantColor: "#ff961e",
  noteColor: "#3784b2",

  white: "#FFFFFF",

  yellow: "#ffc106",
};

export default AppColor;
