import { format } from "date-fns";
import { Sensor, ServerReturnedLog, UserType } from "../model/types";

const is2xx = (code: number) => String(code).startsWith("2");

const servAddr = /localhost|192.168/.test(window.location.hostname)
  ? "http://" + window.location.hostname + ":3001"
  : process.env.REACT_APP_SERVADDR ?? "/server";

const networkErrorHandler = (reloadOnNetworkFailure = true) => (
  data: Response
) => {
  if (data.status === 401) {
    window.location.href = "/login?mustLogin=1";
  } else if (data.status !== 200) {
    reloadOnNetworkFailure && setTimeout(() => window.location.reload(), 5000);
  }
  return data;
};

// Some error codes such as 500 don't cause fetch() to reject. So we do it ourselves.
const non200Handler = (res: Response) => {
  if (res.status !== 200) {
    throw res;
  }
};

const getAuthHeader = () => ({
  authorization: localStorage.getItem("authorization") ?? "",
});

const fetchJSON = (url: RequestInfo, init?: RequestInit) =>
  fetch(url, {
    ...init,
    headers: {
      ...init?.headers,
      ...getAuthHeader(),
    },
  }).then((response) => {
    if (!response.ok) {
      throw response;
    }
    return response.json();
  });

export const fetchDay = (delta: number, viewId: number, relative: boolean) =>
  fetchJSON(
    servAddr + "/day/" + delta + (relative ? "/relative/" : "/") + viewId
  ).catch(networkErrorHandler(true));

export const fetchSites = async () =>
  fetchJSON(servAddr + "/sites").catch(networkErrorHandler(true));

export const fetchViews = async () =>
  fetchJSON(servAddr + "/views").catch(networkErrorHandler(true));

export const fetchLatestTimestamp = async () =>
  fetchJSON(servAddr + "/latestTimestamp", { cache: "no-store" }).catch(
    networkErrorHandler(true)
  );

export const fetchSensors = async (): Promise<Sensor[]> =>
  fetchJSON(servAddr + `/sensors`).catch(console.error);

export const saveSensors = (
  payload: Omit<
    Sensor,
    "alarmDefinitions" | "mapViewEntities" | "site_id" | "inverter_sn"
  >[]
) =>
  fetch(servAddr + "/sensors", {
    method: "POST",
    body: JSON.stringify({
      payload,
    }),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .catch(networkErrorHandler(false))
    .then(non200Handler);

export const fetchSensorsOrder = async (viewId: number): Promise<number[]> =>
  fetchJSON(servAddr + "/sensors_order/" + viewId).catch(
    networkErrorHandler(true)
  );

export const saveSensorsOrder = (viewId: number, sensorsId: number[]) =>
  fetch(servAddr + "/sensors_order", {
    method: "POST",
    body: JSON.stringify({
      viewId,
      sensorsId,
    }),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  })
    .catch(networkErrorHandler(false))
    .then(non200Handler);

export const usersGetTokenInfo = async (
  token: string
): Promise<{
  firstName: string | null;
  lastName: string | null;
  email: string;
}> => {
  const res = await fetch(servAddr + `/users/getTokenInfo/${token}`);
  if (res.status === 400) {
    throw new Error("Invalid token");
  }
  if (!is2xx(res.status)) {
    throw new Error("Unknown error");
  }
  return res.json();
};

export const usersSetPassword = (payload: {
  inviteToken: string;
  password: string;
}) =>
  fetch(servAddr + "/users/setPassword", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  });

export const resetPassword = (payload: { email: string }) =>
  fetch(servAddr + "/users/resetPassword", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  });

export const userSetData = (payload: {
  acceptSms: boolean;
  phoneNumber: string | null;
}) =>
  fetch(servAddr + "/users/userData", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  });

export const userGetData = (): Promise<UserType> =>
  fetchJSON(servAddr + "/users/userData").catch(networkErrorHandler);

export const userSetNotes = (payload: {
  content: string;
  siteId: number;
  sensorId?: number;
}) =>
  fetch(servAddr + "/users/userCreateNote", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  }).catch(networkErrorHandler);

export const updateNoteRead = (payload: { sensorId: number }) =>
  fetch(servAddr + "/users/updateNoteRead", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  }).catch(networkErrorHandler);

export const deleteUserNote = (payload: { noteId: number }) =>
  fetch(servAddr + "/users/deleteUserNote", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  }).catch(networkErrorHandler);

export const getSensorIds = () =>
  fetchJSON(servAddr + "/users/getSensorIdsForUnreadNotes").catch(
    networkErrorHandler
  );

export const userGetNotesBySensorId = (id: number) =>
  fetchJSON(servAddr + `/users/userGetNotesBySensorId/${id}`).catch(
    networkErrorHandler
  );

export const login = async (payload: {
  email: string;
  password: string;
}): Promise<
  | false
  | {
      userId: number;
      token: string;
    }
> => {
  const res = await fetch(servAddr + `/users/login`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  });
  if (res.status === 200) {
    return res.json();
  }
  if (res.status === 401) {
    return false;
  }
  throw new Error("Server error");
};

export const setAlarm = (payload: {
  min_time_between_notifications_minutes: number;
  watch_to: number | null;
  contiguous_matches_before_trigger: number;
  sms: boolean;
  sensor_index: number;
  threshold: number;
  type: "value";
  inverter_id: number;
  operation: string;
  email: boolean;
  watch_from: number | null;
}) =>
  fetch(servAddr + "/alarms/create", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  });

export const deleteAlarm = (payload: { alarmId: number }) =>
  fetch(servAddr + "/alarms/delete", {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json", ...getAuthHeader() },
  });

export const ackAlarm = async (alarmId: number) =>
  fetch(servAddr + "/alarms/ack/" + alarmId, {
    headers: { ...getAuthHeader() },
  });

export const getGraphLogs = (
  viewId: number,
  date: Date,
  relativeValues: boolean
): Promise<{
  [timestamp: string]: {
    [inverterSN: string]: ServerReturnedLog;
  };
}> => {
  console.log(viewId);
  return fetchJSON(
    servAddr +
      `/graph/${viewId}/${format(date, "yyyy-MM-dd")}/${relativeValues}`
  ).catch(networkErrorHandler);
};
