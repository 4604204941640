import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useMemo } from "react";
import { SensorItem, SensorItemProps } from "./SensorItem";

export function SortableSensorItem({
  setTooltipStringInfo,
  relativeValues,
  sensor,
  bypassCoefficients,
  loading,
  id,
  aggregatedInvertersData,
  read,
}: SensorItemProps & { id: string }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    ...(transition && { transition }), // don't add transition if undefined or null
    // because of mysterious build fail that thought transition can be null
  };

  const child = useMemo(
    () => (
      <SensorItem
        read={read}
        setTooltipStringInfo={setTooltipStringInfo}
        aggregatedInvertersData={aggregatedInvertersData}
        relativeValues={relativeValues}
        sensor={sensor}
        bypassCoefficients={bypassCoefficients}
        loading={loading}
      />
    ),
    [
      setTooltipStringInfo,
      aggregatedInvertersData,
      relativeValues,
      sensor,
      bypassCoefficients,
      loading,
      read,
    ]
  );
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {child}
    </div>
  );
}
