import { Box, TextField, InputAdornment, IconButton } from "@material-ui/core";
import {
  GpsFixedOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React from "react";
import { applyCoefficient } from "../../../business/coefficients";
import { StringTooltipParams } from "./StringTooltip";

export function TooltipConfigTab(props: StringTooltipParams) {
  const { sensor, setSensor, relativeValues, tooltipStringInfo } = props;

  let valueWithCoefficient: number | null = applyCoefficient(
    tooltipStringInfo.rawValue,
    sensor.coefficient ?? "x"
  );

  if (valueWithCoefficient !== null && !Number.isFinite(valueWithCoefficient)) {
    valueWithCoefficient = null;
  }

  return (
    <Box
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: 0,
        }}
      >
        <div style={{ fontSize: 10 }}>
          <GpsFixedOutlined
            style={{ verticalAlign: "middle" }}
            fontSize="small"
          />{" "}
          SN {tooltipStringInfo.inverterSN}, String{" "}
          {tooltipStringInfo.stringIndex}
        </div>
        <IconButton
          aria-label={"cacher ce capteur"}
          onClick={() => {
            setSensor({
              ...sensor,
              disabled: !sensor.disabled,
            });
          }}
        >
          {sensor.disabled ? (
            <Visibility style={{ padding: 0 }} />
          ) : (
            <VisibilityOff style={{ padding: 0 }} />
          )}
        </IconButton>
      </div>

      <TextField
        variant="outlined"
        label="Unité (ex. kW, °C, km/h...)"
        value={sensor.unit}
        onChange={(e) =>
          setSensor({
            ...sensor,
            unit: e.currentTarget.value || undefined,
          })
        }
        size="small"
        fullWidth
      />

      {!relativeValues ? (
        <>
          {tooltipStringInfo.rawValue === null && (
            <Alert severity="info">
              Pas de log à cette heure, déplacez le curseur pour régler le
              coefficient
            </Alert>
          )}
          <TextField
            style={{ marginTop: 10 }}
            variant="outlined"
            label="Coefficient"
            error={
              tooltipStringInfo.rawValue !== null &&
              valueWithCoefficient === null
            }
            value={sensor.coefficient ?? ""}
            fullWidth
            placeholder="x"
            disabled={tooltipStringInfo.rawValue === null}
            helperText={
              tooltipStringInfo.rawValue !== null
                ? `𝑓(${tooltipStringInfo.rawValue?.toPrecision(5)}) =
          ${valueWithCoefficient?.toPrecision(5) ?? "ERR"}`
                : undefined
            }
            onChange={(e) =>
              setSensor({
                ...sensor,
                coefficient: e.currentTarget.value.toLocaleLowerCase() || null,
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">𝑓(x) = </InputAdornment>
              ),
            }}
            size="small"
          />
        </>
      ) : (
        <Alert severity="info">
          Coefficient non modifiable en mode valeurs relatives
        </Alert>
      )}
      <TextField
        style={{ marginTop: 10 }}
        variant="outlined"
        label="Description"
        value={sensor.note}
        onChange={(e) =>
          setSensor({
            ...sensor,
            note: e.currentTarget.value || undefined,
          })
        }
        size="small"
        fullWidth
      />
    </Box>
  );
}
