import { Grid, Paper } from "@material-ui/core";
import { VisibilityOff, WifiOff } from "@material-ui/icons";
import React from "react";
import AppColor from "../../../assets/Color";
import {
  AggregatedInverters,
  Sensor,
  TooltipStringInfo,
} from "../../../model/types";
import {
  getStringColor,
  hexToHSL,
  hsl,
  isMobile,
  presentValues,
  presentValuesRelative,
} from "../../../utils/util";
import { ConditionalAlarmBadge } from "./ConditionalAlarmBadge";
import { NoteBadge } from "./NoteBadge";

export interface SensorItemProps {
  setTooltipStringInfo: (x: TooltipStringInfo) => void;
  relativeValues: boolean;
  sensor: Sensor;
  bypassCoefficients: boolean;
  loading: boolean;
  aggregatedInvertersData: AggregatedInverters;
  style?: {
    transform?: string;
    transition?: string;
  };
  read: boolean;
}

export function SensorItem({
  aggregatedInvertersData,
  sensor,
  setTooltipStringInfo,
  relativeValues,
  bypassCoefficients,
  loading,
  read,
}: SensorItemProps) {
  const d: AggregatedInverters = aggregatedInvertersData;
  if (d == undefined) {
    return null;
  }
  const c = getStringColor(d.disabled, relativeValues, d.value);
  const { h, s, l } = hexToHSL(c);

  // HSL values found by trial and error lol
  const textColor = hsl(h, s - 30, l - 5);

  const valueText: string | null = d.disabled
    ? ""
    : d.value === null
    ? null
    : relativeValues
    ? presentValuesRelative(d.value)
    : presentValues(d.value);

  return (
    <Grid
      item
      onClick={(e) =>
        setTooltipStringInfo({
          stringIndex: d.stringIndex,
          inverterId: d.inverter_id,
          anchorEl: e.currentTarget,
          positionX: null,
          positionY: null,
          timestamp: d.timestamp,
          value: d.value,
          rawValue: d.rawValue,
          inverterSN: d.inverter_sn,
        })
      }
      style={{
        userSelect: "none",
        height: "90px",
      }}
    >
      <Paper
        style={{
          backgroundColor: AppColor.lightGrey,
          marginTop: 5,
          marginBottom: 5,
          marginRight: isMobile() ? 2 : 5,
          marginLeft: isMobile() ? 2 : 5,
          fontSize: ".7em",
          fontFamily: "roboto condensed, monospace",
          color: relativeValues
            ? textColor
            : valueText === "0"
            ? "#ff0000"
            : AppColor.textColor,
          borderRadius: "8px",
          height: "55px",
          width: "55px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ConditionalAlarmBadge alarmDefinitions={sensor?.alarmDefinitions} />
        {!read && <NoteBadge />}
        {sensor?.unit && !relativeValues && (
          <span
            style={
              bypassCoefficients
                ? {
                    // if the coefficients are bypassed, the unit cannot be trusted, so strike it and grey it out
                    color: "#555",
                    textDecoration: "line-through",
                  }
                : {}
            }
          >
            {sensor.unit}
          </span>
        )}
        {!d.disabled && valueText !== null ? (
          <span
            style={{
              verticalAlign: "middle",
              fontSize: `${
                Math.min(20, 60 / valueText.length) + 2 // adaptive font size so that it fits in the box whatever the length
              }px`,
            }}
          >
            {loading ? "…" : valueText}
          </span>
        ) : d.disabled ? (
          <VisibilityOff />
        ) : (
          // Icon for null log
          <WifiOff style={{ verticalAlign: "middle" }} />
        )}
      </Paper>
      <div className="label">
        {sensor?.label ?? `Ond.${d.inverter_sn} Str.${d.stringIndex}`}
      </div>
    </Grid>
  );
}
