import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { FormEvent, useState } from "react";
import { UserType } from "../../../model/types";
import { isPhoneNumberValid } from "../../../utils/util";

export default function UserEditInfo({
  userData,
  requestUserUpdate,
  loading,
}: {
  userData: UserType;
  loading: boolean;
  requestUserUpdate: (user: UserType) => void;
}) {
  const initialEmail = userData.email;
  const [firstName, setFirstName] = useState(userData.firstName);
  const [lastName, setLastName] = useState(userData.lastName);
  const [email, setEmail] = useState(userData.email);
  const [phoneNumber, setPhoneNumber] = useState(
    userData.phoneNumber?.replace(/^\+33/, "") ?? ""
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (initialEmail !== email) {
      alert(
        `⚠️ Vous changez d'email. Pensez à utiliser ${email} au lieu de ${initialEmail} pour vous connecter la prochaine fois.`
      );
    }

    if (phoneNumber !== "" && !isPhoneNumberValid(phoneNumber)) {
      alert("Numéro de téléphone invalide");
      return;
    }

    requestUserUpdate({
      ...userData,
      firstName,
      lastName,
      email,
      phoneNumber: phoneNumber === "" ? null : "+33" + phoneNumber,
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label="Prénom"
        value={firstName}
        onChange={(e) => setFirstName(e.currentTarget.value)}
        disabled={loading}
        required
        fullWidth
        style={{ marginBottom: 10 }}
      />
      <TextField
        label="Nom"
        value={lastName}
        onChange={(e) => setLastName(e.currentTarget.value)}
        disabled={loading}
        required
        fullWidth
        style={{ marginBottom: 10 }}
      />
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
        disabled={loading}
        required
        fullWidth
        style={{ marginBottom: 10 }}
      />
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">+33</InputAdornment>,
        }}
        label="Numéro de téléphone portable"
        value={phoneNumber}
        helperText={"Ex:+33 610203040"}
        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        error={phoneNumber !== "" && !isPhoneNumberValid(phoneNumber)} // allow empty
        disabled={loading}
        fullWidth
        style={{ marginBottom: 10 }}
      />
      <Button
        color="primary"
        variant="contained"
        startIcon={loading ? <CircularProgress size={20} /> : <Save />}
        disabled={loading}
        fullWidth
        type="submit"
      >
        Enregistrer
      </Button>
    </form>
  );
}
