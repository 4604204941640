import { CRS, LeafletMouseEvent } from "leaflet";
import "leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet/dist/leaflet.css";
import _ from "lodash";
import React from "react";
import {
  ImageOverlay,
  MapContainer,
  Polygon,
  SVGOverlay,
  useMapEvent,
} from "react-leaflet";
import {
  AggregatedInverters,
  TooltipStringInfo,
  View,
} from "../../model/types";
import {
  getStringColor,
  presentValues,
  presentValuesRelative,
} from "../../utils/util";

export const MapComponent = ({
  setTooltipStringInfo,
  aggregatedInvertersData,
  relativeValues,
  displayDisabledSensors,
  loading,
  view,
}: {
  setTooltipStringInfo: (x: TooltipStringInfo) => void;
  aggregatedInvertersData: AggregatedInverters[];
  relativeValues: boolean;
  displayDisabledSensors: boolean;
  loading: boolean;
  view: View;
}) => {
  const [permanantLabel, setPermanantLabel] = React.useState(false);
  const [zoom, setZoom] = React.useState(-1);

  if (
    view.map_background == null ||
    view.map_background_width == null ||
    view.map_background_height == null
  ) {
    return <>Pas de vue synoptique sur ce view</>;
  }

  return (
    <MapContainer
      fullscreenControl={true}
      key={`mapcontainer-${view.id}`}
      attributionControl={false}
      preferCanvas={true}
      style={{
        width: "100%",
        flexGrow: 1,
        backgroundColor: "rgba(255, 0, 0, 0)",
      }}
      // ZOOM MIN POUR VOIR TOUTE L'IMAGE SUR LA MAP
      minZoom={-2}
      crs={CRS.Simple}
      // TAILLE DE L'IMAGE DE FOND
      bounds={[
        [0, 0],
        [view.map_background_height, view.map_background_width],
      ]}
    >
      <ImageOverlay
        bounds={[
          // TAILLE DE L'IMAGE DE FOND
          [0, 0],
          [view.map_background_height, view.map_background_width],
        ]}
        // URL DE L'IMAGE DE FOND
        url={view.map_background}
      />
      {(aggregatedInvertersData ?? []).map((data, i) =>
        data.mapViewEntitiesForSensor.map((mapViewEntity, j) => {
          if (data.disabled && !displayDisabledSensors) {
            return;
          }

          const polygonPosition = _.chunk(mapViewEntity.poly_coords, 2) as [
            number,
            number
          ][];

          return (
            <>
              <Polygon
                key={j}
                eventHandlers={{
                  click: (event) => {
                    setTooltipStringInfo({
                      stringIndex: data.stringIndex,
                      inverterId: data.inverter_id,
                      anchorEl: event.target,
                      positionX: event.originalEvent.clientX,
                      positionY: event.originalEvent.clientY,
                      timestamp: data.timestamp,
                      value: data.value,
                      rawValue: data.rawValue,
                      inverterSN: data.inverter_sn,
                    });
                  },
                }}
                pathOptions={{
                  color: getStringColor(false, relativeValues, data.value),
                }}
                positions={polygonPosition}
              />

              <SVGOverlay
                // POSITION TEXTE SUR L'IMAGE (coin bas gauche, bas droite, haut droite, haut gauche)
                bounds={polygonPosition}
                key={"o" + j}
              >
                {view.text_zoom_threshold === 0 || permanantLabel ? (
                  <text x="50%" y="50%" fill="white" textAnchor="middle">
                    {
                      loading
                        ? "…"
                        : data.value === null
                        ? ""
                        : relativeValues
                        ? presentValuesRelative(data.value)
                        : presentValues(data.value)
                      // +
                      //   (zoom > (view.text_zoom_threshold ?? 0) && data.unit
                      //     ? data.unit
                      //     : "")
                    }
                  </text>
                ) : null}
              </SVGOverlay>
            </>
          );
        })
      )}
      <ZoomEvent
        setZoomLevel={setZoom}
        setPermanantLabel={setPermanantLabel}
        text_zoom_threshold={view.text_zoom_threshold ?? 2}
      ></ZoomEvent>
      <ClickEvent></ClickEvent>
    </MapContainer>
  );
};

// Juste pour le débug, affiche les coordonnées sur la carte au clic
function ClickEvent() {
  useMapEvent("click", (e: LeafletMouseEvent) => {
    console.log(e.latlng);
  });
  return null;
}

function ZoomEvent({
  setPermanantLabel,
  setZoomLevel,
  text_zoom_threshold,
}: {
  setPermanantLabel: (visible: boolean) => void;
  setZoomLevel: (zoom: number) => void;
  text_zoom_threshold: number;
}) {
  const map = useMapEvent("zoom", () => {
    // ZOOM A PARTIR D'OU ON AFFICHE LE TEXTE
    setPermanantLabel(map.getZoom() >= text_zoom_threshold);
    setZoomLevel(map.getZoom());
  });
  return null;
}
