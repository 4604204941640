import * as React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Button, makeStyles } from "@material-ui/core";
import { Notes } from "../../../../model/types";
import frLocale from "date-fns/locale/fr";
import { format, formatDistanceToNowStrict, isSameDay } from "date-fns";
import AppColor from "../../../../assets/Color";
import ColorHash from "color-hash-ts";

export const ShowNotesComponent = (props: {
  notes: Notes[];
  deleteNote: (noteId: number) => void;
}) => {
  const userGenerateColor = (userId: number) => {
    let color = new ColorHash();
    return color.hex(String(userId));
  };
  const notes = props.notes;
  const deleteNote = props.deleteNote;

  const formatNoteDate = (date: Date): string => {
    if (isSameDay(new Date(), date)) {
      return `il y a ${formatDistanceToNowStrict(date, {
        locale: frLocale,
      })}`;
    } else {
      return format(date, "'le' dd/MM/yy 'à' HH:mm");
    }
  };

  const useStyles = makeStyles({
    textBubble: {
      fontSize: 14,
      padding: 0,
      paddingLeft: 3,
      wordBreak: "break-word",
      whiteSpace: "pre-line",
    },
    notesContainer: {
      marginTop: 5,
      marginBottom: 5,
      padding: 3,
      width: "90%",
      marginRight: "auto",
      borderRadius: 5,
      backgroundColor: AppColor.lightGrey,
    },
    userInfoContainer: {
      display: "flex",
      alignItems: "center",
    },
    userFirstName: {
      fontSize: "13px",
      padding: 0,
      margin: 0,
    },
    notesSendDate: {
      fontSize: "9px",
      color: AppColor.greyIcon,
      alignSelf: "center",
      marginLeft: "auto",
      padding: 0,
      margin: 0,
    },
  });

  const classes = useStyles();
  const userId = Number(localStorage.getItem("authorization")?.split(",")[0]);

  return (
    <>
      {notes.map((note, index) => (
        <div
          className={classes.notesContainer}
          style={{
            border: `1px solid ${userGenerateColor(note.user_id)}`,
            borderLeft: `5px solid ${userGenerateColor(note.user_id)}`,
          }}
          key={index}
        >
          <div className={classes.userInfoContainer}>
            <p
              className={classes.userFirstName}
              style={{ color: userGenerateColor(note.user_id) }}
            >
              {note.first_name}
            </p>
            <p className={classes.notesSendDate}>
              {formatNoteDate(new Date(note.send_at))}
            </p>
            {userId === note.user_id && (
              <DeleteForeverIcon
                style={{ fontSize: "18px", color: "red" }}
                onClick={() => deleteNote(note.id!)}
              />
            )}
          </div>
          <p className={classes.textBubble}>{note.content}</p>
        </div>
      ))}
    </>
  );
};
