import { Box, Button } from "@material-ui/core";
import { InsertChartOutlined } from "@material-ui/icons";
import React from "react";
import { StringTooltipParams } from "./StringTooltip";

export function TooltipGraphTab(props: StringTooltipParams) {
  const [graphIsOpen, setGraphOpen] = React.useState(false);
  const handleClickGraph = () => {
    setGraphOpen(true);
  };

  return (
    <div
      className="note-bg"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height={"100%"}
        width={"100%"}
      >
        <Button
          color="primary"
          onClick={() => handleClickGraph()}
          startIcon={<InsertChartOutlined />}
          fullWidth
        >
          Voir le graphique
        </Button>
      </Box>
    </div>
  );
}
