import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { Box, Container, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { isSameDay } from "date-fns/esm";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import AppColor from "../../assets/Color";
import { applyCoefficient } from "../../business/coefficients";
import {
  AggregatedInverters,
  Sensor,
  ServerReturnedLog,
  ServerReturnedLogs,
  Site,
  TooltipStringInfo,
  UserType,
  View,
} from "../../model/types";
import {
  fetchSensorsOrder,
  saveSensors,
  saveSensorsOrder,
} from "../../repository/network";
import { MapComponent } from "../component/MapComponent";
import { SensorItem } from "../component/singleSiteView/SensorItem";
import { SortableSensorItem } from "../component/singleSiteView/SortableSensorItem";
import { StringTooltip } from "../component/tooltip/StringTooltip";
import { WaitingForLogs } from "../component/WaitingForLogs";

const isSensorInArray = (
  readState: { id: number }[],
  sensorId: number
): boolean => {
  return readState.some((item) => item.id === sensorId);
};

export const SingleSiteView = ({
  view,
  site,
  loading,
  firstDataLoaded,
  timestamp,
  filteredData,
  relativeValues,
  sensors,
  displayDisabledSensors,
  bypassCoefficients,
  goToTimestamp,
  setSensors,
  showError,
  userData,
  readState,
  updateUserData,
}: {
  view: View;
  site: Site | null;
  loading: boolean;
  firstDataLoaded: boolean;
  timestamp: number;
  filteredData: ServerReturnedLogs | null;
  relativeValues: boolean;
  sensors: Sensor[];
  displayDisabledSensors: boolean;
  bypassCoefficients: boolean;
  goToTimestamp: (timestamp: number) => void;
  setSensors: (sensors: Sensor[]) => void;
  showError: (text: string) => void;
  userData: UserType;
  readState: { id: number }[];
  updateUserData: (x: UserType) => Promise<void>;
}) => {
  const dataForSite = filteredData?.[timestamp];
  const aggregatedInvertersDataBySensorId: {
    [id: string]: AggregatedInverters;
  } = {};
  const saveSensorsDebounced = useCallback(
    _.debounce((sensors: Sensor[]) => {
      saveSensors(sensors).catch((e) =>
        showError(`Impossible de mettre à jour les infobulles`)
      );
    }, 500),
    []
  );

  const [tooltipStringInfo, setTooltipStringInfo] = useState<TooltipStringInfo>(
    {
      anchorEl: null,
      positionY: null,
      positionX: null,
      inverterSN: "",
      inverterId: -1,
      stringIndex: -1,
      timestamp: -1,
      value: -1,
      rawValue: -1,
    }
  );

  //TODO: lookup by id
  const currentSensor: Sensor = sensors.find(
    (d) =>
      d.inverter_id === tooltipStringInfo.inverterId &&
      d.sensor_index === tooltipStringInfo.stringIndex
  ) ?? {
    inverter_id: tooltipStringInfo.inverterId,
    sensor_index: tooltipStringInfo.stringIndex,
  };

  const mapViewEntitiesBySensorId = _.groupBy(
    view.mapViewEntities ?? [],
    "sensor_id"
  );

  const sensorsById = _.keyBy(sensors, "id");

  for (const inverterId in dataForSite) {
    const invLog = dataForSite?.[inverterId];
    if (invLog.inverter_sn == "_weather") continue;
    const values = (invLog?.values ?? {}) as ServerReturnedLog["values"];
    _.keys(values).forEach((key) => {
      const sensorId = parseInt(key);
      const rawValue = values[sensorId];
      const sensor = sensorsById[sensorId];
      if (sensor == undefined) {
        console.error(`Capteur ${sensorId} dans les logs non reconnu.`);
        return;
      }
      aggregatedInvertersDataBySensorId[sensorId] = {
        value:
          relativeValues || bypassCoefficients
            ? rawValue
            : applyCoefficient(rawValue, sensor?.coefficient),
        rawValue,
        stringIndex: sensor.sensor_index,
        inverter_sn: invLog.inverter_sn,
        inverter_id: parseInt(inverterId), // == invLog.inverter_id
        timestamp: invLog?.timestamp ?? 0,
        disabled: sensor?.disabled ?? false,
        mapViewEntitiesForSensor: mapViewEntitiesBySensorId[sensor.id!] ?? [],
        unit: sensor.unit,
      };
    });
  }

  // Le timestamp le plus tardif contenant un onduleur non méta (e.g. _weather)
  const latestKeyForView = _(filteredData ?? {})
    .keys()
    .sort()
    .findLast((timestamp) => {
      const inverterSNsForTimestampAndSites = _.values(
        _.mapValues(
          filteredData?.[timestamp],
          (inverterData) => inverterData.inverter_sn
        )
      );
      return inverterSNsForTimestampAndSites.some((sn) => !sn.startsWith("_"));
    });

  const latestTimestampForView =
    latestKeyForView !== undefined ? Number(latestKeyForView) : undefined;

  const hasVisibleSensorsAtThisTimestamp = _.values(
    aggregatedInvertersDataBySensorId
  ).some((sensor) => sensor.disabled === false);

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (firstDataLoaded === true) {
      if (
        latestTimestampForView != undefined &&
        timestamp > latestTimestampForView &&
        !hasVisibleSensorsAtThisTimestamp
      ) {
        goToTimestamp(latestTimestampForView);
      }
    }
  }, [view.id, firstDataLoaded]);

  useEffect(() => {
    if (!site && tabValue !== 0) setTabValue(0);
  }, [site]);

  // ids of displayed sensors  in the right order
  const [sensorsId, setSensorsId] = useState<string[]>([]);

  useEffect(() => {
    fetchSensorsOrder(view.id).then((orderedSensorsId) => {
      const sensorsId = orderedSensorsId
        .filter((sensorId) =>
          aggregatedInvertersDataBySensorId[sensorId]
            ? displayDisabledSensors ||
              !aggregatedInvertersDataBySensorId[sensorId].disabled
            : false
        )
        .map((sensorId) => sensorId.toString());
      setSensorsId(sensorsId);
    });
  }, [filteredData?.[timestamp], displayDisabledSensors, sensors]);

  const dndSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // For displaying the tooltip on click
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 5,
      },
    })
  );
  const [activeId, setActiveId] = useState<string | null>(null);

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over == null) {
      // Can be triggered when simply clicking on sensor
      return sensorsId;
    }
    if (active.id !== over.id) {
      setSensorsId((sensorsId) => {
        const oldIndex = sensorsId.indexOf(active.id);
        const newIndex = sensorsId.indexOf(over.id);
        const newSensorsId = arrayMove(sensorsId, oldIndex, newIndex);
        saveSensorsOrder(
          view.id,
          newSensorsId.map((sensorId) => parseInt(sensorId))
        );
        return newSensorsId;
      });
    }
    setActiveId(null);
  }

  return (
    <Box
      style={{ backgroundColor: AppColor.greyBackground }}
      flexGrow={1}
      display="flex"
      flexDirection="column"
    >
      <Box>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="CAPTEURS" />
            {view.map_background && <Tab label="SYNOPTIQUE" />}
          </Tabs>
        </Paper>
      </Box>
      {tabValue == 0 ? (
        <Box pt={3}>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="stretch"
            >
              {latestTimestampForView !== undefined &&
              isSameDay(timestamp, latestTimestampForView) &&
              timestamp > latestTimestampForView &&
              !hasVisibleSensorsAtThisTimestamp ? (
                <WaitingForLogs
                  timestamp={timestamp}
                  latestTimestampForView={latestTimestampForView}
                  onClick={() => goToTimestamp(latestTimestampForView!)}
                />
              ) : !hasVisibleSensorsAtThisTimestamp ? (
                <Container>Pas de log à cette heure</Container>
              ) : (
                <DndContext
                  sensors={dndSensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                >
                  <SortableContext
                    items={sensorsId}
                    strategy={rectSortingStrategy}
                  >
                    {sensorsId.map((id) => {
                      return (
                        <SortableSensorItem
                          read={!isSensorInArray(readState, parseInt(id))}
                          setTooltipStringInfo={setTooltipStringInfo}
                          aggregatedInvertersData={
                            aggregatedInvertersDataBySensorId[id]
                          }
                          sensor={sensorsById[id]}
                          relativeValues={relativeValues}
                          bypassCoefficients={bypassCoefficients}
                          loading={loading}
                          key={id}
                          id={id}
                        />
                      );
                    })}
                  </SortableContext>
                  <DragOverlay>
                    {activeId != null && (
                      <div style={{ transform: "scale(1.6)" }}>
                        <SensorItem
                          read={!isSensorInArray(readState, currentSensor.id!)}
                          setTooltipStringInfo={setTooltipStringInfo}
                          aggregatedInvertersData={
                            aggregatedInvertersDataBySensorId[activeId]
                          }
                          relativeValues={relativeValues}
                          sensor={sensorsById[activeId]}
                          bypassCoefficients={bypassCoefficients}
                          loading={loading}
                        />
                      </div>
                    )}
                  </DragOverlay>
                </DndContext>
              )}
            </Grid>
          </Container>
        </Box>
      ) : view.map_background ? (
        <MapComponent
          view={view}
          setTooltipStringInfo={setTooltipStringInfo}
          aggregatedInvertersData={_.values(aggregatedInvertersDataBySensorId)}
          relativeValues={relativeValues}
          displayDisabledSensors={displayDisabledSensors}
          loading={loading}
        />
      ) : (
        <></>
      )}

      <StringTooltip
        userData={userData}
        updateUserData={updateUserData}
        view={view}
        viewSensorsId={sensorsId}
        sensors={sensors}
        sensor={currentSensor}
        relativeValues={relativeValues}
        setSensor={(sensor: Sensor, persist = true) => {
          const newSensors = [...sensors];
          let sensorIndex = newSensors.findIndex(
            (s) =>
              s.inverter_id === sensor.inverter_id &&
              s.sensor_index === sensor.sensor_index
          );
          if (sensorIndex === -1) {
            console.error("No such sensor");
            return;
          }
          newSensors[sensorIndex] = sensor;

          setSensors(newSensors);
          if (persist) {
            saveSensorsDebounced([
              _.omit(sensor, [
                "mapViewEntities",
                "alarmDefinitions",
                "site_id",
                "inverter_sn",
              ]),
            ]);
          }
        }}
        tooltipStringInfo={tooltipStringInfo}
        setAnchorEl={(el) => {
          setTooltipStringInfo({ ...tooltipStringInfo, anchorEl: el });
        }}
      />
    </Box>
  );
};
